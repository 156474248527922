import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class AccountItemForecast {
  getAccountItemForecastInfo(account_item_id) {
    return axios.get(
      `/api/admin/account-item-forecast/compare-real/`,
      {
        params: {
          account_item_id: account_item_id,
        },
      },
      config
    );
  }
  getAccountItemForecastList(
    account_item_id,
    year_and_month_begin,
    year_and_month_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/account-item-forecast/compare-real/list`,
      {
        params: {
          account_item_id: account_item_id,
          begin_time: year_and_month_begin,
          end_time: year_and_month_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchAccountItemForecast(datas) {
    return axios.post(
      `/api/admin/account-item-forecast/compare-real/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addAccountItemForecast(account_item_id, year_and_month, estimated_revenue) {
    return axios.post(
      `/api/admin/account-item-forecast/`,
      {
        account_item_id: account_item_id,
        year_and_month: year_and_month,
        estimated_revenue: estimated_revenue,
      },
      config
    );
  }
  editAccountItemForecast(account_item_id, year_and_month, estimated_revenue) {
    return axios.put(
      `/api/admin/account-item-forecast/`,
      {
        account_item_id: account_item_id,
        year_and_month: year_and_month,
        estimated_revenue: estimated_revenue,
      },
      config
    );
  }
  deleteAccountItemForecast(account_item_ids, year_and_month) {
    return axios.delete(
      `/api/admin/account-item-forecast/`,
      {
        data: {
          account_item_ids: account_item_ids,
          year_and_month: year_and_month,
        },
      },
      config
    );
  }
}
