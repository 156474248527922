<script setup>
import MegaMenu from "primevue/megamenu";
import PanelMenu from "primevue/panelmenu";
import Dropdown from "primevue/dropdown";
import { ref, reactive } from "vue";
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import Toolbar from "primevue/toolbar";
import router from "../router";
import cookie from "../cookie";
import { useStore } from "vuex";
import { useI18n } from "../i18nPlugin";
import logo from "../assets/leyu-logo-w.png";
import User from "../api/salary/auth/user";
import firebase from "firebase";
import { useToast } from "primevue/usetoast";

const toast = useToast();

let userApi = new User();

let cookieSetting = new cookie();
const i18n = useI18n();
const store = useStore();
defineProps({
  //   video_stream: String,
});

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

function Logout() {
  userApi.logoutUser().then((res) => {
    if (res.data.error_code == 0) {
      CheckUser();
      routerSwitch("/");
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function langSwitch() {
  cookieSetting.doCookieSetup("lang", store.state.lang);
  i18n.locale.value = store.state.lang;
  location.reload();
}
function routerSwitch(url) {
  router.push(url);
}
const data = reactive({
  visibleLeft: false,
  lang: [
    { name: "中文", code: "cn" },
    { name: "English", code: "en" },
  ],
  expandedKeys: {},
  settings:
    store.state.user == null
      ? []
      : store.state.user.role == 1
      ? store.state.user.role_addition_binding == 100
        ? store.state.user.id == 981 || store.state.user.id == 982
          ? store.state.user.organisation_id == 1
            ? [
                {
                  label: i18n.$t("NavBar").Info.Label,
                  to: i18n.$t("NavBar").Info.Link,
                  icon: i18n.$t("NavBar").Info.Logo,
                },
                {
                  label: i18n.$t("NavBar").PaymentSetting.Label,
                  icon: i18n.$t("NavBar").PaymentSetting.Logo,
                  items: [
                    {
                      label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                      to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                      icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                    },
                  ],
                },
              ]
            : store.state.user.salary >= 200
            ? [
                {
                  label: i18n.$t("NavBar").Document.Label,
                  to: i18n.$t("NavBar").Document.Link,
                  icon: i18n.$t("NavBar").Document.Logo,
                },
                {
                  label: i18n.$t("NavBar").Info.Label,
                  to: i18n.$t("NavBar").Info.Link,
                  icon: i18n.$t("NavBar").Info.Logo,
                },
                {
                  label: i18n.$t("NavBar").PaymentSetting.Label,
                  icon: i18n.$t("NavBar").PaymentSetting.Logo,
                  items: [
                    {
                      label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                      to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                      icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                    },
                  ],
                },
              ]
            : [
                {
                  label: i18n.$t("NavBar").Info.Label,
                  to: i18n.$t("NavBar").Info.Link,
                  icon: i18n.$t("NavBar").Info.Logo,
                },
                {
                  label: i18n.$t("NavBar").PaymentSetting.Label,
                  icon: i18n.$t("NavBar").PaymentSetting.Logo,
                  items: [
                    {
                      label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                      to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                      icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                    },
                  ],
                },
              ]
          : store.state.user.organisation_id == 1
          ? [
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              {
                label: i18n.$t("NavBar").Salary.Label,
                icon: i18n.$t("NavBar").Salary.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").EmployerAdmin.Label,
                    to: i18n.$t("NavBar").EmployerAdmin.Link,
                    icon: i18n.$t("NavBar").EmployerAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                    to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                    icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
                    to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
                    icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
                    to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
                    icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
                    to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
                    icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
                    to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
                    icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
                    to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
                    icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplyBouns.Label,
                    to: i18n.$t("NavBar").ApplyBouns.Link,
                    icon: i18n.$t("NavBar").ApplyBouns.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditBounsAdmin.Label,
                    to: i18n.$t("NavBar").AuditBounsAdmin.Link,
                    icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SalaryAdmin.Label,
                    to: i18n.$t("NavBar").SalaryAdmin.Link,
                    icon: i18n.$t("NavBar").SalaryAdmin.Logo,
                  },
                ],
              },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditPayment.Label,
                    to: i18n.$t("NavBar").AuditPayment.Link,
                    icon: i18n.$t("NavBar").AuditPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditAmount.Label,
                    to: i18n.$t("NavBar").AuditAmount.Link,
                    icon: i18n.$t("NavBar").AuditAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSellment.Label,
                    to: i18n.$t("NavBar").AuditSellment.Link,
                    icon: i18n.$t("NavBar").AuditSellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPayment.Label,
                    to: i18n.$t("NavBar").HistoryPayment.Link,
                    icon: i18n.$t("NavBar").HistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistorySellment.Label,
                    to: i18n.$t("NavBar").HistorySellment.Link,
                    icon: i18n.$t("NavBar").HistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").History.Label,
                    to: i18n.$t("NavBar").History.Link,
                    icon: i18n.$t("NavBar").History.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPie.Label,
                    to: i18n.$t("NavBar").HistoryPie.Link,
                    icon: i18n.$t("NavBar").HistoryPie.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                ],
              },
              {
                label: i18n.$t("NavBar").Setting.Label,
                icon: i18n.$t("NavBar").Setting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Organisations.Label,
                    to: i18n.$t("NavBar").Organisations.Link,
                    icon: i18n.$t("NavBar").Organisations.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Department.Label,
                    to: i18n.$t("NavBar").Department.Link,
                    icon: i18n.$t("NavBar").Department.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Nationality.Label,
                    to: i18n.$t("NavBar").Nationality.Link,
                    icon: i18n.$t("NavBar").Nationality.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Position.Label,
                    to: i18n.$t("NavBar").Position.Link,
                    icon: i18n.$t("NavBar").Position.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").WorkPermit.Label,
                    to: i18n.$t("NavBar").WorkPermit.Link,
                    icon: i18n.$t("NavBar").WorkPermit.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ExchangeRate.Label,
                    to: i18n.$t("NavBar").ExchangeRate.Link,
                    icon: i18n.$t("NavBar").ExchangeRate.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Currency.Label,
                    to: i18n.$t("NavBar").Currency.Link,
                    icon: i18n.$t("NavBar").Currency.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SalaryItem.Label,
                    to: i18n.$t("NavBar").SalaryItem.Link,
                    icon: i18n.$t("NavBar").SalaryItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HolidayItem.Label,
                    to: i18n.$t("NavBar").HolidayItem.Link,
                    icon: i18n.$t("NavBar").HolidayItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Factory.Label,
                    to: i18n.$t("NavBar").Factory.Link,
                    icon: i18n.$t("NavBar").Factory.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Company.Label,
                    to: i18n.$t("NavBar").Company.Link,
                    icon: i18n.$t("NavBar").Company.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").CompanyAccount.Label,
                    to: i18n.$t("NavBar").CompanyAccount.Link,
                    icon: i18n.$t("NavBar").CompanyAccount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AccountType.Label,
                    to: i18n.$t("NavBar").AccountType.Link,
                    icon: i18n.$t("NavBar").AccountType.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AccountItem.Label,
                    to: i18n.$t("NavBar").AccountItem.Link,
                    icon: i18n.$t("NavBar").AccountItem.Logo,
                  },

                  {
                    label: i18n.$t("NavBar").PaymentItem.Label,
                    to: i18n.$t("NavBar").PaymentItem.Link,
                    icon: i18n.$t("NavBar").PaymentItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").DocumentType.Label,
                    to: i18n.$t("NavBar").DocumentType.Link,
                    icon: i18n.$t("NavBar").DocumentType.Logo,
                  },
                ],
              },
            ]
          : store.state.user.salary >= 200
          ? [
              {
                label: i18n.$t("NavBar").Document.Label,
                to: i18n.$t("NavBar").Document.Link,
                icon: i18n.$t("NavBar").Document.Logo,
              },
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              {
                label: i18n.$t("NavBar").Salary.Label,
                icon: i18n.$t("NavBar").Salary.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").EmployerAdmin.Label,
                    to: i18n.$t("NavBar").EmployerAdmin.Link,
                    icon: i18n.$t("NavBar").EmployerAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                    to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                    icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
                    to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
                    icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
                    to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
                    icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
                    to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
                    icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
                    to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
                    icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
                    to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
                    icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplyBouns.Label,
                    to: i18n.$t("NavBar").ApplyBouns.Link,
                    icon: i18n.$t("NavBar").ApplyBouns.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditBounsAdmin.Label,
                    to: i18n.$t("NavBar").AuditBounsAdmin.Link,
                    icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SalaryAdmin.Label,
                    to: i18n.$t("NavBar").SalaryAdmin.Link,
                    icon: i18n.$t("NavBar").SalaryAdmin.Logo,
                  },
                ],
              },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditPayment.Label,
                    to: i18n.$t("NavBar").AuditPayment.Link,
                    icon: i18n.$t("NavBar").AuditPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditAmount.Label,
                    to: i18n.$t("NavBar").AuditAmount.Link,
                    icon: i18n.$t("NavBar").AuditAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSellment.Label,
                    to: i18n.$t("NavBar").AuditSellment.Link,
                    icon: i18n.$t("NavBar").AuditSellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPayment.Label,
                    to: i18n.$t("NavBar").HistoryPayment.Link,
                    icon: i18n.$t("NavBar").HistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistorySellment.Label,
                    to: i18n.$t("NavBar").HistorySellment.Link,
                    icon: i18n.$t("NavBar").HistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").History.Label,
                    to: i18n.$t("NavBar").History.Link,
                    icon: i18n.$t("NavBar").History.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPie.Label,
                    to: i18n.$t("NavBar").HistoryPie.Link,
                    icon: i18n.$t("NavBar").HistoryPie.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                ],
              },
              {
                label: i18n.$t("NavBar").Setting.Label,
                icon: i18n.$t("NavBar").Setting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Organisations.Label,
                    to: i18n.$t("NavBar").Organisations.Link,
                    icon: i18n.$t("NavBar").Organisations.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Department.Label,
                    to: i18n.$t("NavBar").Department.Link,
                    icon: i18n.$t("NavBar").Department.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Nationality.Label,
                    to: i18n.$t("NavBar").Nationality.Link,
                    icon: i18n.$t("NavBar").Nationality.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Position.Label,
                    to: i18n.$t("NavBar").Position.Link,
                    icon: i18n.$t("NavBar").Position.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").WorkPermit.Label,
                    to: i18n.$t("NavBar").WorkPermit.Link,
                    icon: i18n.$t("NavBar").WorkPermit.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ExchangeRate.Label,
                    to: i18n.$t("NavBar").ExchangeRate.Link,
                    icon: i18n.$t("NavBar").ExchangeRate.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Currency.Label,
                    to: i18n.$t("NavBar").Currency.Link,
                    icon: i18n.$t("NavBar").Currency.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SalaryItem.Label,
                    to: i18n.$t("NavBar").SalaryItem.Link,
                    icon: i18n.$t("NavBar").SalaryItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HolidayItem.Label,
                    to: i18n.$t("NavBar").HolidayItem.Link,
                    icon: i18n.$t("NavBar").HolidayItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Factory.Label,
                    to: i18n.$t("NavBar").Factory.Link,
                    icon: i18n.$t("NavBar").Factory.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Company.Label,
                    to: i18n.$t("NavBar").Company.Link,
                    icon: i18n.$t("NavBar").Company.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").CompanyAccount.Label,
                    to: i18n.$t("NavBar").CompanyAccount.Link,
                    icon: i18n.$t("NavBar").CompanyAccount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AccountType.Label,
                    to: i18n.$t("NavBar").AccountType.Link,
                    icon: i18n.$t("NavBar").AccountType.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AccountItem.Label,
                    to: i18n.$t("NavBar").AccountItem.Link,
                    icon: i18n.$t("NavBar").AccountItem.Logo,
                  },

                  {
                    label: i18n.$t("NavBar").PaymentItem.Label,
                    to: i18n.$t("NavBar").PaymentItem.Link,
                    icon: i18n.$t("NavBar").PaymentItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").DocumentType.Label,
                    to: i18n.$t("NavBar").DocumentType.Link,
                    icon: i18n.$t("NavBar").DocumentType.Logo,
                  },
                ],
              },
            ]
          : [
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              {
                label: i18n.$t("NavBar").Salary.Label,
                icon: i18n.$t("NavBar").Salary.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").EmployerAdmin.Label,
                    to: i18n.$t("NavBar").EmployerAdmin.Link,
                    icon: i18n.$t("NavBar").EmployerAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                    to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                    icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
                    to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
                    icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
                    to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
                    icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
                    to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
                    icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
                    to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
                    icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
                    to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
                    icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplyBouns.Label,
                    to: i18n.$t("NavBar").ApplyBouns.Link,
                    icon: i18n.$t("NavBar").ApplyBouns.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditBounsAdmin.Label,
                    to: i18n.$t("NavBar").AuditBounsAdmin.Link,
                    icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SalaryAdmin.Label,
                    to: i18n.$t("NavBar").SalaryAdmin.Link,
                    icon: i18n.$t("NavBar").SalaryAdmin.Logo,
                  },
                ],
              },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditPayment.Label,
                    to: i18n.$t("NavBar").AuditPayment.Link,
                    icon: i18n.$t("NavBar").AuditPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditAmount.Label,
                    to: i18n.$t("NavBar").AuditAmount.Link,
                    icon: i18n.$t("NavBar").AuditAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSellment.Label,
                    to: i18n.$t("NavBar").AuditSellment.Link,
                    icon: i18n.$t("NavBar").AuditSellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPayment.Label,
                    to: i18n.$t("NavBar").HistoryPayment.Link,
                    icon: i18n.$t("NavBar").HistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistorySellment.Label,
                    to: i18n.$t("NavBar").HistorySellment.Link,
                    icon: i18n.$t("NavBar").HistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").History.Label,
                    to: i18n.$t("NavBar").History.Link,
                    icon: i18n.$t("NavBar").History.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPie.Label,
                    to: i18n.$t("NavBar").HistoryPie.Link,
                    icon: i18n.$t("NavBar").HistoryPie.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                ],
              },
              {
                label: i18n.$t("NavBar").Setting.Label,
                icon: i18n.$t("NavBar").Setting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Organisations.Label,
                    to: i18n.$t("NavBar").Organisations.Link,
                    icon: i18n.$t("NavBar").Organisations.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Department.Label,
                    to: i18n.$t("NavBar").Department.Link,
                    icon: i18n.$t("NavBar").Department.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Nationality.Label,
                    to: i18n.$t("NavBar").Nationality.Link,
                    icon: i18n.$t("NavBar").Nationality.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Position.Label,
                    to: i18n.$t("NavBar").Position.Link,
                    icon: i18n.$t("NavBar").Position.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").WorkPermit.Label,
                    to: i18n.$t("NavBar").WorkPermit.Link,
                    icon: i18n.$t("NavBar").WorkPermit.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ExchangeRate.Label,
                    to: i18n.$t("NavBar").ExchangeRate.Link,
                    icon: i18n.$t("NavBar").ExchangeRate.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Currency.Label,
                    to: i18n.$t("NavBar").Currency.Link,
                    icon: i18n.$t("NavBar").Currency.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SalaryItem.Label,
                    to: i18n.$t("NavBar").SalaryItem.Link,
                    icon: i18n.$t("NavBar").SalaryItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HolidayItem.Label,
                    to: i18n.$t("NavBar").HolidayItem.Link,
                    icon: i18n.$t("NavBar").HolidayItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Factory.Label,
                    to: i18n.$t("NavBar").Factory.Link,
                    icon: i18n.$t("NavBar").Factory.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Company.Label,
                    to: i18n.$t("NavBar").Company.Link,
                    icon: i18n.$t("NavBar").Company.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").CompanyAccount.Label,
                    to: i18n.$t("NavBar").CompanyAccount.Link,
                    icon: i18n.$t("NavBar").CompanyAccount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AccountType.Label,
                    to: i18n.$t("NavBar").AccountType.Link,
                    icon: i18n.$t("NavBar").AccountType.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AccountItem.Label,
                    to: i18n.$t("NavBar").AccountItem.Link,
                    icon: i18n.$t("NavBar").AccountItem.Logo,
                  },

                  {
                    label: i18n.$t("NavBar").PaymentItem.Label,
                    to: i18n.$t("NavBar").PaymentItem.Link,
                    icon: i18n.$t("NavBar").PaymentItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").DocumentType.Label,
                    to: i18n.$t("NavBar").DocumentType.Link,
                    icon: i18n.$t("NavBar").DocumentType.Logo,
                  },
                ],
              },
            ]
        : store.state.user.role_addition_binding == 101
        ? store.state.user.id == 126 || store.state.user.id == 199
          ? store.state.user.organisation_id == 1
            ? [
                {
                  label: i18n.$t("NavBar").Info.Label,
                  to: i18n.$t("NavBar").Info.Link,
                  icon: i18n.$t("NavBar").Info.Logo,
                },
                {
                  label: i18n.$t("NavBar").Salary.Label,
                  icon: i18n.$t("NavBar").Salary.Logo,
                  items: [
                    {
                      label: i18n.$t("NavBar").EmployerAdmin.Label,
                      to: i18n.$t("NavBar").EmployerAdmin.Link,
                      icon: i18n.$t("NavBar").EmployerAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                      to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                      icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
                      to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
                      icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
                      to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
                      icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
                      to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
                      icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
                      to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
                      icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
                      to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
                      icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").ApplyBouns.Label,
                      to: i18n.$t("NavBar").ApplyBouns.Link,
                      icon: i18n.$t("NavBar").ApplyBouns.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditBounsAdmin.Label,
                      to: i18n.$t("NavBar").AuditBounsAdmin.Link,
                      icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").SalaryAdmin.Label,
                      to: i18n.$t("NavBar").SalaryAdmin.Link,
                      icon: i18n.$t("NavBar").SalaryAdmin.Logo,
                    },
                  ],
                },
                {
                  label: i18n.$t("NavBar").PaymentSetting.Label,
                  icon: i18n.$t("NavBar").PaymentSetting.Logo,
                  items: [
                    {
                      label: i18n.$t("NavBar").Payment.Label,
                      to: i18n.$t("NavBar").Payment.Link,
                      icon: i18n.$t("NavBar").Payment.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditPayment.Label,
                      to: i18n.$t("NavBar").AuditPayment.Link,
                      icon: i18n.$t("NavBar").AuditPayment.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").ApplicationAmount.Label,
                      to: i18n.$t("NavBar").ApplicationAmount.Link,
                      icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                    },
                    // {
                    //   label: i18n.$t("NavBar").AuditAmount.Label,
                    //   to: i18n.$t("NavBar").AuditAmount.Link,
                    //   icon: i18n.$t("NavBar").AuditAmount.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").Sellment.Label,
                    //   to: i18n.$t("NavBar").Sellment.Link,
                    //   icon: i18n.$t("NavBar").Sellment.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").AuditSellment.Label,
                    //   to: i18n.$t("NavBar").AuditSellment.Link,
                    //   icon: i18n.$t("NavBar").AuditSellment.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    //   to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    //   icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").HistoryPayment.Label,
                    //   to: i18n.$t("NavBar").HistoryPayment.Link,
                    //   icon: i18n.$t("NavBar").HistoryPayment.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").HistorySellment.Label,
                    //   to: i18n.$t("NavBar").HistorySellment.Link,
                    //   icon: i18n.$t("NavBar").HistorySellment.Logo,
                    // },
                    {
                      label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                      to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                      icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").SelfHistorySellment.Label,
                      to: i18n.$t("NavBar").SelfHistorySellment.Link,
                      icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                    },
                    // {
                    //   label: i18n.$t("NavBar").History.Label,
                    //   to: i18n.$t("NavBar").History.Link,
                    //   icon: i18n.$t("NavBar").History.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").HistoryPie.Label,
                    //   to: i18n.$t("NavBar").HistoryPie.Link,
                    //   icon: i18n.$t("NavBar").HistoryPie.Logo,
                    // },
                  ],
                },
              ]
            : store.state.user.salary >= 200
            ? [
                {
                  label: i18n.$t("NavBar").Document.Label,
                  to: i18n.$t("NavBar").Document.Link,
                  icon: i18n.$t("NavBar").Document.Logo,
                },
                {
                  label: i18n.$t("NavBar").Info.Label,
                  to: i18n.$t("NavBar").Info.Link,
                  icon: i18n.$t("NavBar").Info.Logo,
                },
                {
                  label: i18n.$t("NavBar").Salary.Label,
                  icon: i18n.$t("NavBar").Salary.Logo,
                  items: [
                    {
                      label: i18n.$t("NavBar").EmployerAdmin.Label,
                      to: i18n.$t("NavBar").EmployerAdmin.Link,
                      icon: i18n.$t("NavBar").EmployerAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                      to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                      icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
                      to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
                      icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
                      to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
                      icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
                      to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
                      icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
                      to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
                      icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
                      to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
                      icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").ApplyBouns.Label,
                      to: i18n.$t("NavBar").ApplyBouns.Link,
                      icon: i18n.$t("NavBar").ApplyBouns.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditBounsAdmin.Label,
                      to: i18n.$t("NavBar").AuditBounsAdmin.Link,
                      icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").SalaryAdmin.Label,
                      to: i18n.$t("NavBar").SalaryAdmin.Link,
                      icon: i18n.$t("NavBar").SalaryAdmin.Logo,
                    },
                  ],
                },
                {
                  label: i18n.$t("NavBar").PaymentSetting.Label,
                  icon: i18n.$t("NavBar").PaymentSetting.Logo,
                  items: [
                    {
                      label: i18n.$t("NavBar").Payment.Label,
                      to: i18n.$t("NavBar").Payment.Link,
                      icon: i18n.$t("NavBar").Payment.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditPayment.Label,
                      to: i18n.$t("NavBar").AuditPayment.Link,
                      icon: i18n.$t("NavBar").AuditPayment.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").ApplicationAmount.Label,
                      to: i18n.$t("NavBar").ApplicationAmount.Link,
                      icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                    },
                    // {
                    //   label: i18n.$t("NavBar").AuditAmount.Label,
                    //   to: i18n.$t("NavBar").AuditAmount.Link,
                    //   icon: i18n.$t("NavBar").AuditAmount.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").Sellment.Label,
                    //   to: i18n.$t("NavBar").Sellment.Link,
                    //   icon: i18n.$t("NavBar").Sellment.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").AuditSellment.Label,
                    //   to: i18n.$t("NavBar").AuditSellment.Link,
                    //   icon: i18n.$t("NavBar").AuditSellment.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    //   to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    //   icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").HistoryPayment.Label,
                    //   to: i18n.$t("NavBar").HistoryPayment.Link,
                    //   icon: i18n.$t("NavBar").HistoryPayment.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").HistorySellment.Label,
                    //   to: i18n.$t("NavBar").HistorySellment.Link,
                    //   icon: i18n.$t("NavBar").HistorySellment.Logo,
                    // },
                    {
                      label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                      to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                      icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").SelfHistorySellment.Label,
                      to: i18n.$t("NavBar").SelfHistorySellment.Link,
                      icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                    },
                    // {
                    //   label: i18n.$t("NavBar").History.Label,
                    //   to: i18n.$t("NavBar").History.Link,
                    //   icon: i18n.$t("NavBar").History.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").HistoryPie.Label,
                    //   to: i18n.$t("NavBar").HistoryPie.Link,
                    //   icon: i18n.$t("NavBar").HistoryPie.Logo,
                    // },
                  ],
                },
              ]
            : [
                {
                  label: i18n.$t("NavBar").Info.Label,
                  to: i18n.$t("NavBar").Info.Link,
                  icon: i18n.$t("NavBar").Info.Logo,
                },
                {
                  label: i18n.$t("NavBar").Salary.Label,
                  icon: i18n.$t("NavBar").Salary.Logo,
                  items: [
                    {
                      label: i18n.$t("NavBar").EmployerAdmin.Label,
                      to: i18n.$t("NavBar").EmployerAdmin.Link,
                      icon: i18n.$t("NavBar").EmployerAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                      to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                      icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
                      to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
                      icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
                      to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
                      icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
                      to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
                      icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
                      to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
                      icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
                      to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
                      icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").ApplyBouns.Label,
                      to: i18n.$t("NavBar").ApplyBouns.Link,
                      icon: i18n.$t("NavBar").ApplyBouns.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditBounsAdmin.Label,
                      to: i18n.$t("NavBar").AuditBounsAdmin.Link,
                      icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").SalaryAdmin.Label,
                      to: i18n.$t("NavBar").SalaryAdmin.Link,
                      icon: i18n.$t("NavBar").SalaryAdmin.Logo,
                    },
                  ],
                },
                {
                  label: i18n.$t("NavBar").PaymentSetting.Label,
                  icon: i18n.$t("NavBar").PaymentSetting.Logo,
                  items: [
                    {
                      label: i18n.$t("NavBar").Payment.Label,
                      to: i18n.$t("NavBar").Payment.Link,
                      icon: i18n.$t("NavBar").Payment.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").AuditPayment.Label,
                      to: i18n.$t("NavBar").AuditPayment.Link,
                      icon: i18n.$t("NavBar").AuditPayment.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").ApplicationAmount.Label,
                      to: i18n.$t("NavBar").ApplicationAmount.Link,
                      icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                    },
                    // {
                    //   label: i18n.$t("NavBar").AuditAmount.Label,
                    //   to: i18n.$t("NavBar").AuditAmount.Link,
                    //   icon: i18n.$t("NavBar").AuditAmount.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").Sellment.Label,
                    //   to: i18n.$t("NavBar").Sellment.Link,
                    //   icon: i18n.$t("NavBar").Sellment.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").AuditSellment.Label,
                    //   to: i18n.$t("NavBar").AuditSellment.Link,
                    //   icon: i18n.$t("NavBar").AuditSellment.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    //   to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    //   icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").HistoryPayment.Label,
                    //   to: i18n.$t("NavBar").HistoryPayment.Link,
                    //   icon: i18n.$t("NavBar").HistoryPayment.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").HistorySellment.Label,
                    //   to: i18n.$t("NavBar").HistorySellment.Link,
                    //   icon: i18n.$t("NavBar").HistorySellment.Logo,
                    // },
                    {
                      label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                      to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                      icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                    },
                    {
                      label: i18n.$t("NavBar").SelfHistorySellment.Label,
                      to: i18n.$t("NavBar").SelfHistorySellment.Link,
                      icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                    },
                    // {
                    //   label: i18n.$t("NavBar").History.Label,
                    //   to: i18n.$t("NavBar").History.Link,
                    //   icon: i18n.$t("NavBar").History.Logo,
                    // },
                    // {
                    //   label: i18n.$t("NavBar").HistoryPie.Label,
                    //   to: i18n.$t("NavBar").HistoryPie.Link,
                    //   icon: i18n.$t("NavBar").HistoryPie.Logo,
                    // },
                  ],
                },
              ]
          : store.state.user.organisation_id == 1
          ? [
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              {
                label: i18n.$t("NavBar").Salary.Label,
                icon: i18n.$t("NavBar").Salary.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").EmployerAdmin.Label,
                    to: i18n.$t("NavBar").EmployerAdmin.Link,
                    icon: i18n.$t("NavBar").EmployerAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                    to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                    icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
                    to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
                    icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
                    to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
                    icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
                    to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
                    icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
                    to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
                    icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
                    to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
                    icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplyBouns.Label,
                    to: i18n.$t("NavBar").ApplyBouns.Link,
                    icon: i18n.$t("NavBar").ApplyBouns.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditBounsAdmin.Label,
                    to: i18n.$t("NavBar").AuditBounsAdmin.Link,
                    icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SalaryAdmin.Label,
                    to: i18n.$t("NavBar").SalaryAdmin.Link,
                    icon: i18n.$t("NavBar").SalaryAdmin.Logo,
                  },
                ],
              },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditPayment.Label,
                    to: i18n.$t("NavBar").AuditPayment.Link,
                    icon: i18n.$t("NavBar").AuditPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditAmount.Label,
                    to: i18n.$t("NavBar").AuditAmount.Link,
                    icon: i18n.$t("NavBar").AuditAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSellment.Label,
                    to: i18n.$t("NavBar").AuditSellment.Link,
                    icon: i18n.$t("NavBar").AuditSellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPayment.Label,
                    to: i18n.$t("NavBar").HistoryPayment.Link,
                    icon: i18n.$t("NavBar").HistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistorySellment.Label,
                    to: i18n.$t("NavBar").HistorySellment.Link,
                    icon: i18n.$t("NavBar").HistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").History.Label,
                    to: i18n.$t("NavBar").History.Link,
                    icon: i18n.$t("NavBar").History.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPie.Label,
                    to: i18n.$t("NavBar").HistoryPie.Link,
                    icon: i18n.$t("NavBar").HistoryPie.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                ],
              },
            ]
          : store.state.user.salary >= 200
          ? [
              {
                label: i18n.$t("NavBar").Document.Label,
                to: i18n.$t("NavBar").Document.Link,
                icon: i18n.$t("NavBar").Document.Logo,
              },
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              {
                label: i18n.$t("NavBar").Salary.Label,
                icon: i18n.$t("NavBar").Salary.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").EmployerAdmin.Label,
                    to: i18n.$t("NavBar").EmployerAdmin.Link,
                    icon: i18n.$t("NavBar").EmployerAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                    to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                    icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
                    to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
                    icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
                    to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
                    icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
                    to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
                    icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
                    to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
                    icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
                    to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
                    icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplyBouns.Label,
                    to: i18n.$t("NavBar").ApplyBouns.Link,
                    icon: i18n.$t("NavBar").ApplyBouns.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditBounsAdmin.Label,
                    to: i18n.$t("NavBar").AuditBounsAdmin.Link,
                    icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SalaryAdmin.Label,
                    to: i18n.$t("NavBar").SalaryAdmin.Link,
                    icon: i18n.$t("NavBar").SalaryAdmin.Logo,
                  },
                ],
              },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditPayment.Label,
                    to: i18n.$t("NavBar").AuditPayment.Link,
                    icon: i18n.$t("NavBar").AuditPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditAmount.Label,
                    to: i18n.$t("NavBar").AuditAmount.Link,
                    icon: i18n.$t("NavBar").AuditAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSellment.Label,
                    to: i18n.$t("NavBar").AuditSellment.Link,
                    icon: i18n.$t("NavBar").AuditSellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPayment.Label,
                    to: i18n.$t("NavBar").HistoryPayment.Link,
                    icon: i18n.$t("NavBar").HistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistorySellment.Label,
                    to: i18n.$t("NavBar").HistorySellment.Link,
                    icon: i18n.$t("NavBar").HistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").History.Label,
                    to: i18n.$t("NavBar").History.Link,
                    icon: i18n.$t("NavBar").History.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPie.Label,
                    to: i18n.$t("NavBar").HistoryPie.Link,
                    icon: i18n.$t("NavBar").HistoryPie.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                ],
              },
            ]
          : [
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              {
                label: i18n.$t("NavBar").Salary.Label,
                icon: i18n.$t("NavBar").Salary.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").EmployerAdmin.Label,
                    to: i18n.$t("NavBar").EmployerAdmin.Link,
                    icon: i18n.$t("NavBar").EmployerAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                    to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                    icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
                    to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
                    icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
                    to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
                    icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
                    to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
                    icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
                    to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
                    icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
                    to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
                    icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplyBouns.Label,
                    to: i18n.$t("NavBar").ApplyBouns.Link,
                    icon: i18n.$t("NavBar").ApplyBouns.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditBounsAdmin.Label,
                    to: i18n.$t("NavBar").AuditBounsAdmin.Link,
                    icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SalaryAdmin.Label,
                    to: i18n.$t("NavBar").SalaryAdmin.Link,
                    icon: i18n.$t("NavBar").SalaryAdmin.Logo,
                  },
                ],
              },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditPayment.Label,
                    to: i18n.$t("NavBar").AuditPayment.Link,
                    icon: i18n.$t("NavBar").AuditPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditAmount.Label,
                    to: i18n.$t("NavBar").AuditAmount.Link,
                    icon: i18n.$t("NavBar").AuditAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSellment.Label,
                    to: i18n.$t("NavBar").AuditSellment.Link,
                    icon: i18n.$t("NavBar").AuditSellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPayment.Label,
                    to: i18n.$t("NavBar").HistoryPayment.Link,
                    icon: i18n.$t("NavBar").HistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistorySellment.Label,
                    to: i18n.$t("NavBar").HistorySellment.Link,
                    icon: i18n.$t("NavBar").HistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").History.Label,
                    to: i18n.$t("NavBar").History.Link,
                    icon: i18n.$t("NavBar").History.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPie.Label,
                    to: i18n.$t("NavBar").HistoryPie.Link,
                    icon: i18n.$t("NavBar").HistoryPie.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                ],
              },
            ]
        : store.state.user.role_addition_binding == 102
        ? store.state.user.organisation_id == 1
          ? [
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              // {
              //   label: i18n.$t("NavBar").Salary.Label,
              //   icon: i18n.$t("NavBar").Salary.Logo,
              //   items: [
              //     {
              //       label: i18n.$t("NavBar").EmployerAdmin.Label,
              //       to: i18n.$t("NavBar").EmployerAdmin.Link,
              //       icon: i18n.$t("NavBar").EmployerAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
              //       to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
              //       icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
              //       to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
              //       to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
              //       to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
              //       to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
              //       to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").ApplyBouns.Label,
              //       to: i18n.$t("NavBar").ApplyBouns.Link,
              //       icon: i18n.$t("NavBar").ApplyBouns.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditBounsAdmin.Label,
              //       to: i18n.$t("NavBar").AuditBounsAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
              //     },
              //     // {
              //     //   label: i18n.$t("NavBar").SalaryAdmin.Label,
              //     //   to: i18n.$t("NavBar").SalaryAdmin.Link,
              //     //   icon: i18n.$t("NavBar").SalaryAdmin.Logo,
              //     // },
              //   ],
              // },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditPayment.Label,
                    to: i18n.$t("NavBar").AuditPayment.Link,
                    icon: i18n.$t("NavBar").AuditPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditAmount.Label,
                    to: i18n.$t("NavBar").AuditAmount.Link,
                    icon: i18n.$t("NavBar").AuditAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSellment.Label,
                    to: i18n.$t("NavBar").AuditSellment.Link,
                    icon: i18n.$t("NavBar").AuditSellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPayment.Label,
                    to: i18n.$t("NavBar").HistoryPayment.Link,
                    icon: i18n.$t("NavBar").HistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistorySellment.Label,
                    to: i18n.$t("NavBar").HistorySellment.Link,
                    icon: i18n.$t("NavBar").HistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").History.Label,
                    to: i18n.$t("NavBar").History.Link,
                    icon: i18n.$t("NavBar").History.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPie.Label,
                    to: i18n.$t("NavBar").HistoryPie.Link,
                    icon: i18n.$t("NavBar").HistoryPie.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                ],
              },
              {
                label: i18n.$t("NavBar").Setting.Label,
                icon: i18n.$t("NavBar").Setting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Organisations.Label,
                    to: i18n.$t("NavBar").Organisations.Link,
                    icon: i18n.$t("NavBar").Organisations.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Department.Label,
                    to: i18n.$t("NavBar").Department.Link,
                    icon: i18n.$t("NavBar").Department.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Nationality.Label,
                    to: i18n.$t("NavBar").Nationality.Link,
                    icon: i18n.$t("NavBar").Nationality.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Position.Label,
                    to: i18n.$t("NavBar").Position.Link,
                    icon: i18n.$t("NavBar").Position.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").WorkPermit.Label,
                    to: i18n.$t("NavBar").WorkPermit.Link,
                    icon: i18n.$t("NavBar").WorkPermit.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ExchangeRate.Label,
                    to: i18n.$t("NavBar").ExchangeRate.Link,
                    icon: i18n.$t("NavBar").ExchangeRate.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Currency.Label,
                    to: i18n.$t("NavBar").Currency.Link,
                    icon: i18n.$t("NavBar").Currency.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SalaryItem.Label,
                    to: i18n.$t("NavBar").SalaryItem.Link,
                    icon: i18n.$t("NavBar").SalaryItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HolidayItem.Label,
                    to: i18n.$t("NavBar").HolidayItem.Link,
                    icon: i18n.$t("NavBar").HolidayItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Factory.Label,
                    to: i18n.$t("NavBar").Factory.Link,
                    icon: i18n.$t("NavBar").Factory.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Company.Label,
                    to: i18n.$t("NavBar").Company.Link,
                    icon: i18n.$t("NavBar").Company.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").CompanyAccount.Label,
                    to: i18n.$t("NavBar").CompanyAccount.Link,
                    icon: i18n.$t("NavBar").CompanyAccount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AccountType.Label,
                    to: i18n.$t("NavBar").AccountType.Link,
                    icon: i18n.$t("NavBar").AccountType.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AccountItem.Label,
                    to: i18n.$t("NavBar").AccountItem.Link,
                    icon: i18n.$t("NavBar").AccountItem.Logo,
                  },

                  {
                    label: i18n.$t("NavBar").PaymentItem.Label,
                    to: i18n.$t("NavBar").PaymentItem.Link,
                    icon: i18n.$t("NavBar").PaymentItem.Logo,
                  },
                ],
              },
            ]
          : store.state.user.salary >= 200
          ? [
              {
                label: i18n.$t("NavBar").Document.Label,
                to: i18n.$t("NavBar").Document.Link,
                icon: i18n.$t("NavBar").Document.Logo,
              },
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              // {
              //   label: i18n.$t("NavBar").Salary.Label,
              //   icon: i18n.$t("NavBar").Salary.Logo,
              //   items: [
              //     {
              //       label: i18n.$t("NavBar").EmployerAdmin.Label,
              //       to: i18n.$t("NavBar").EmployerAdmin.Link,
              //       icon: i18n.$t("NavBar").EmployerAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
              //       to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
              //       icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
              //       to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
              //       to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
              //       to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
              //       to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
              //       to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").ApplyBouns.Label,
              //       to: i18n.$t("NavBar").ApplyBouns.Link,
              //       icon: i18n.$t("NavBar").ApplyBouns.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditBounsAdmin.Label,
              //       to: i18n.$t("NavBar").AuditBounsAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
              //     },
              //     // {
              //     //   label: i18n.$t("NavBar").SalaryAdmin.Label,
              //     //   to: i18n.$t("NavBar").SalaryAdmin.Link,
              //     //   icon: i18n.$t("NavBar").SalaryAdmin.Logo,
              //     // },
              //   ],
              // },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditPayment.Label,
                    to: i18n.$t("NavBar").AuditPayment.Link,
                    icon: i18n.$t("NavBar").AuditPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditAmount.Label,
                    to: i18n.$t("NavBar").AuditAmount.Link,
                    icon: i18n.$t("NavBar").AuditAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSellment.Label,
                    to: i18n.$t("NavBar").AuditSellment.Link,
                    icon: i18n.$t("NavBar").AuditSellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPayment.Label,
                    to: i18n.$t("NavBar").HistoryPayment.Link,
                    icon: i18n.$t("NavBar").HistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistorySellment.Label,
                    to: i18n.$t("NavBar").HistorySellment.Link,
                    icon: i18n.$t("NavBar").HistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").History.Label,
                    to: i18n.$t("NavBar").History.Link,
                    icon: i18n.$t("NavBar").History.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPie.Label,
                    to: i18n.$t("NavBar").HistoryPie.Link,
                    icon: i18n.$t("NavBar").HistoryPie.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                ],
              },
              {
                label: i18n.$t("NavBar").Setting.Label,
                icon: i18n.$t("NavBar").Setting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Organisations.Label,
                    to: i18n.$t("NavBar").Organisations.Link,
                    icon: i18n.$t("NavBar").Organisations.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Department.Label,
                    to: i18n.$t("NavBar").Department.Link,
                    icon: i18n.$t("NavBar").Department.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Nationality.Label,
                    to: i18n.$t("NavBar").Nationality.Link,
                    icon: i18n.$t("NavBar").Nationality.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Position.Label,
                    to: i18n.$t("NavBar").Position.Link,
                    icon: i18n.$t("NavBar").Position.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").WorkPermit.Label,
                    to: i18n.$t("NavBar").WorkPermit.Link,
                    icon: i18n.$t("NavBar").WorkPermit.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ExchangeRate.Label,
                    to: i18n.$t("NavBar").ExchangeRate.Link,
                    icon: i18n.$t("NavBar").ExchangeRate.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Currency.Label,
                    to: i18n.$t("NavBar").Currency.Link,
                    icon: i18n.$t("NavBar").Currency.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SalaryItem.Label,
                    to: i18n.$t("NavBar").SalaryItem.Link,
                    icon: i18n.$t("NavBar").SalaryItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HolidayItem.Label,
                    to: i18n.$t("NavBar").HolidayItem.Link,
                    icon: i18n.$t("NavBar").HolidayItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Factory.Label,
                    to: i18n.$t("NavBar").Factory.Link,
                    icon: i18n.$t("NavBar").Factory.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Company.Label,
                    to: i18n.$t("NavBar").Company.Link,
                    icon: i18n.$t("NavBar").Company.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").CompanyAccount.Label,
                    to: i18n.$t("NavBar").CompanyAccount.Link,
                    icon: i18n.$t("NavBar").CompanyAccount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AccountType.Label,
                    to: i18n.$t("NavBar").AccountType.Link,
                    icon: i18n.$t("NavBar").AccountType.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AccountItem.Label,
                    to: i18n.$t("NavBar").AccountItem.Link,
                    icon: i18n.$t("NavBar").AccountItem.Logo,
                  },

                  {
                    label: i18n.$t("NavBar").PaymentItem.Label,
                    to: i18n.$t("NavBar").PaymentItem.Link,
                    icon: i18n.$t("NavBar").PaymentItem.Logo,
                  },
                ],
              },
            ]
          : [
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              // {
              //   label: i18n.$t("NavBar").Salary.Label,
              //   icon: i18n.$t("NavBar").Salary.Logo,
              //   items: [
              //     {
              //       label: i18n.$t("NavBar").EmployerAdmin.Label,
              //       to: i18n.$t("NavBar").EmployerAdmin.Link,
              //       icon: i18n.$t("NavBar").EmployerAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
              //       to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
              //       icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
              //       to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
              //       to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
              //       to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
              //       to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
              //       to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").ApplyBouns.Label,
              //       to: i18n.$t("NavBar").ApplyBouns.Link,
              //       icon: i18n.$t("NavBar").ApplyBouns.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditBounsAdmin.Label,
              //       to: i18n.$t("NavBar").AuditBounsAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
              //     },
              //     // {
              //     //   label: i18n.$t("NavBar").SalaryAdmin.Label,
              //     //   to: i18n.$t("NavBar").SalaryAdmin.Link,
              //     //   icon: i18n.$t("NavBar").SalaryAdmin.Logo,
              //     // },
              //   ],
              // },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditPayment.Label,
                    to: i18n.$t("NavBar").AuditPayment.Link,
                    icon: i18n.$t("NavBar").AuditPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditAmount.Label,
                    to: i18n.$t("NavBar").AuditAmount.Link,
                    icon: i18n.$t("NavBar").AuditAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSellment.Label,
                    to: i18n.$t("NavBar").AuditSellment.Link,
                    icon: i18n.$t("NavBar").AuditSellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPayment.Label,
                    to: i18n.$t("NavBar").HistoryPayment.Link,
                    icon: i18n.$t("NavBar").HistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistorySellment.Label,
                    to: i18n.$t("NavBar").HistorySellment.Link,
                    icon: i18n.$t("NavBar").HistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").History.Label,
                    to: i18n.$t("NavBar").History.Link,
                    icon: i18n.$t("NavBar").History.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPie.Label,
                    to: i18n.$t("NavBar").HistoryPie.Link,
                    icon: i18n.$t("NavBar").HistoryPie.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                ],
              },
              {
                label: i18n.$t("NavBar").Setting.Label,
                icon: i18n.$t("NavBar").Setting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Organisations.Label,
                    to: i18n.$t("NavBar").Organisations.Link,
                    icon: i18n.$t("NavBar").Organisations.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Department.Label,
                    to: i18n.$t("NavBar").Department.Link,
                    icon: i18n.$t("NavBar").Department.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Nationality.Label,
                    to: i18n.$t("NavBar").Nationality.Link,
                    icon: i18n.$t("NavBar").Nationality.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Position.Label,
                    to: i18n.$t("NavBar").Position.Link,
                    icon: i18n.$t("NavBar").Position.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").WorkPermit.Label,
                    to: i18n.$t("NavBar").WorkPermit.Link,
                    icon: i18n.$t("NavBar").WorkPermit.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ExchangeRate.Label,
                    to: i18n.$t("NavBar").ExchangeRate.Link,
                    icon: i18n.$t("NavBar").ExchangeRate.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Currency.Label,
                    to: i18n.$t("NavBar").Currency.Link,
                    icon: i18n.$t("NavBar").Currency.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SalaryItem.Label,
                    to: i18n.$t("NavBar").SalaryItem.Link,
                    icon: i18n.$t("NavBar").SalaryItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HolidayItem.Label,
                    to: i18n.$t("NavBar").HolidayItem.Link,
                    icon: i18n.$t("NavBar").HolidayItem.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Factory.Label,
                    to: i18n.$t("NavBar").Factory.Link,
                    icon: i18n.$t("NavBar").Factory.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Company.Label,
                    to: i18n.$t("NavBar").Company.Link,
                    icon: i18n.$t("NavBar").Company.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").CompanyAccount.Label,
                    to: i18n.$t("NavBar").CompanyAccount.Link,
                    icon: i18n.$t("NavBar").CompanyAccount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AccountType.Label,
                    to: i18n.$t("NavBar").AccountType.Link,
                    icon: i18n.$t("NavBar").AccountType.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AccountItem.Label,
                    to: i18n.$t("NavBar").AccountItem.Link,
                    icon: i18n.$t("NavBar").AccountItem.Logo,
                  },

                  {
                    label: i18n.$t("NavBar").PaymentItem.Label,
                    to: i18n.$t("NavBar").PaymentItem.Link,
                    icon: i18n.$t("NavBar").PaymentItem.Logo,
                  },
                ],
              },
            ]
        : store.state.user.role_addition_binding == 103
        ? store.state.user.organisation_id == 1
          ? [
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              // {
              //   label: i18n.$t("NavBar").Salary.Label,
              //   icon: i18n.$t("NavBar").Salary.Logo,
              //   items: [
              //     {
              //       label: i18n.$t("NavBar").EmployerAdmin.Label,
              //       to: i18n.$t("NavBar").EmployerAdmin.Link,
              //       icon: i18n.$t("NavBar").EmployerAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
              //       to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
              //       icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
              //       to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
              //       to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
              //       to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
              //       to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
              //       to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").ApplyBouns.Label,
              //       to: i18n.$t("NavBar").ApplyBouns.Link,
              //       icon: i18n.$t("NavBar").ApplyBouns.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditBounsAdmin.Label,
              //       to: i18n.$t("NavBar").AuditBounsAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").SalaryAdmin.Label,
              //       to: i18n.$t("NavBar").SalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").SalaryAdmin.Logo,
              //     },
              //   ],
              // },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  // {
                  //   label: i18n.$t("NavBar").AuditPayment.Label,
                  //   to: i18n.$t("NavBar").AuditPayment.Link,
                  //   icon: i18n.$t("NavBar").AuditPayment.Logo,
                  // },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditAmount.Label,
                    to: i18n.$t("NavBar").AuditAmount.Link,
                    icon: i18n.$t("NavBar").AuditAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSellment.Label,
                    to: i18n.$t("NavBar").AuditSellment.Link,
                    icon: i18n.$t("NavBar").AuditSellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPayment.Label,
                    to: i18n.$t("NavBar").HistoryPayment.Link,
                    icon: i18n.$t("NavBar").HistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistorySellment.Label,
                    to: i18n.$t("NavBar").HistorySellment.Link,
                    icon: i18n.$t("NavBar").HistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").History.Label,
                    to: i18n.$t("NavBar").History.Link,
                    icon: i18n.$t("NavBar").History.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPie.Label,
                    to: i18n.$t("NavBar").HistoryPie.Link,
                    icon: i18n.$t("NavBar").HistoryPie.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                ],
              },
            ]
          : store.state.user.salary >= 200
          ? [
              {
                label: i18n.$t("NavBar").Document.Label,
                to: i18n.$t("NavBar").Document.Link,
                icon: i18n.$t("NavBar").Document.Logo,
              },
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              // {
              //   label: i18n.$t("NavBar").Salary.Label,
              //   icon: i18n.$t("NavBar").Salary.Logo,
              //   items: [
              //     {
              //       label: i18n.$t("NavBar").EmployerAdmin.Label,
              //       to: i18n.$t("NavBar").EmployerAdmin.Link,
              //       icon: i18n.$t("NavBar").EmployerAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
              //       to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
              //       icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
              //       to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
              //       to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
              //       to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
              //       to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
              //       to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").ApplyBouns.Label,
              //       to: i18n.$t("NavBar").ApplyBouns.Link,
              //       icon: i18n.$t("NavBar").ApplyBouns.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditBounsAdmin.Label,
              //       to: i18n.$t("NavBar").AuditBounsAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").SalaryAdmin.Label,
              //       to: i18n.$t("NavBar").SalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").SalaryAdmin.Logo,
              //     },
              //   ],
              // },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  // {
                  //   label: i18n.$t("NavBar").AuditPayment.Label,
                  //   to: i18n.$t("NavBar").AuditPayment.Link,
                  //   icon: i18n.$t("NavBar").AuditPayment.Logo,
                  // },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditAmount.Label,
                    to: i18n.$t("NavBar").AuditAmount.Link,
                    icon: i18n.$t("NavBar").AuditAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSellment.Label,
                    to: i18n.$t("NavBar").AuditSellment.Link,
                    icon: i18n.$t("NavBar").AuditSellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPayment.Label,
                    to: i18n.$t("NavBar").HistoryPayment.Link,
                    icon: i18n.$t("NavBar").HistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistorySellment.Label,
                    to: i18n.$t("NavBar").HistorySellment.Link,
                    icon: i18n.$t("NavBar").HistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").History.Label,
                    to: i18n.$t("NavBar").History.Link,
                    icon: i18n.$t("NavBar").History.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPie.Label,
                    to: i18n.$t("NavBar").HistoryPie.Link,
                    icon: i18n.$t("NavBar").HistoryPie.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                ],
              },
            ]
          : [
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              // {
              //   label: i18n.$t("NavBar").Salary.Label,
              //   icon: i18n.$t("NavBar").Salary.Logo,
              //   items: [
              //     {
              //       label: i18n.$t("NavBar").EmployerAdmin.Label,
              //       to: i18n.$t("NavBar").EmployerAdmin.Link,
              //       icon: i18n.$t("NavBar").EmployerAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
              //       to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
              //       icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
              //       to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
              //       to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
              //       to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
              //       to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
              //       to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").ApplyBouns.Label,
              //       to: i18n.$t("NavBar").ApplyBouns.Link,
              //       icon: i18n.$t("NavBar").ApplyBouns.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").AuditBounsAdmin.Label,
              //       to: i18n.$t("NavBar").AuditBounsAdmin.Link,
              //       icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
              //     },
              //     {
              //       label: i18n.$t("NavBar").SalaryAdmin.Label,
              //       to: i18n.$t("NavBar").SalaryAdmin.Link,
              //       icon: i18n.$t("NavBar").SalaryAdmin.Logo,
              //     },
              //   ],
              // },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  // {
                  //   label: i18n.$t("NavBar").AuditPayment.Label,
                  //   to: i18n.$t("NavBar").AuditPayment.Link,
                  //   icon: i18n.$t("NavBar").AuditPayment.Logo,
                  // },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditAmount.Label,
                    to: i18n.$t("NavBar").AuditAmount.Link,
                    icon: i18n.$t("NavBar").AuditAmount.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditSellment.Label,
                    to: i18n.$t("NavBar").AuditSellment.Link,
                    icon: i18n.$t("NavBar").AuditSellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                    to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                    icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPayment.Label,
                    to: i18n.$t("NavBar").HistoryPayment.Link,
                    icon: i18n.$t("NavBar").HistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistorySellment.Label,
                    to: i18n.$t("NavBar").HistorySellment.Link,
                    icon: i18n.$t("NavBar").HistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").History.Label,
                    to: i18n.$t("NavBar").History.Link,
                    icon: i18n.$t("NavBar").History.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").HistoryPie.Label,
                    to: i18n.$t("NavBar").HistoryPie.Link,
                    icon: i18n.$t("NavBar").HistoryPie.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                ],
              },
            ]
        : store.state.user.organisation_id == 1
        ? [
            {
              label: i18n.$t("NavBar").Info.Label,
              to: i18n.$t("NavBar").Info.Link,
              icon: i18n.$t("NavBar").Info.Logo,
            },
            {
              label: i18n.$t("NavBar").Salary.Label,
              icon: i18n.$t("NavBar").Salary.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").EmployerAdmin.Label,
                  to: i18n.$t("NavBar").EmployerAdmin.Link,
                  icon: i18n.$t("NavBar").EmployerAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                  to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                  icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
                  to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
                  icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
                  to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
                  icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
                  to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
                  icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
                  to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
                  icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
                  to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
                  icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplyBouns.Label,
                  to: i18n.$t("NavBar").ApplyBouns.Link,
                  icon: i18n.$t("NavBar").ApplyBouns.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditBounsAdmin.Label,
                  to: i18n.$t("NavBar").AuditBounsAdmin.Link,
                  icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").SalaryAdmin.Label,
                  to: i18n.$t("NavBar").SalaryAdmin.Link,
                  icon: i18n.$t("NavBar").SalaryAdmin.Logo,
                },
              ],
            },
            {
              label: i18n.$t("NavBar").PaymentSetting.Label,
              icon: i18n.$t("NavBar").PaymentSetting.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").Payment.Label,
                  to: i18n.$t("NavBar").Payment.Link,
                  icon: i18n.$t("NavBar").Payment.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditPayment.Label,
                  to: i18n.$t("NavBar").AuditPayment.Link,
                  icon: i18n.$t("NavBar").AuditPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplicationAmount.Label,
                  to: i18n.$t("NavBar").ApplicationAmount.Link,
                  icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditAmount.Label,
                  to: i18n.$t("NavBar").AuditAmount.Link,
                  icon: i18n.$t("NavBar").AuditAmount.Logo,
                },
                {
                  label: i18n.$t("NavBar").Sellment.Label,
                  to: i18n.$t("NavBar").Sellment.Link,
                  icon: i18n.$t("NavBar").Sellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditSellment.Label,
                  to: i18n.$t("NavBar").AuditSellment.Link,
                  icon: i18n.$t("NavBar").AuditSellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                  to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                  icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                },
                {
                  label: i18n.$t("NavBar").HistoryPayment.Label,
                  to: i18n.$t("NavBar").HistoryPayment.Link,
                  icon: i18n.$t("NavBar").HistoryPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").HistorySellment.Label,
                  to: i18n.$t("NavBar").HistorySellment.Link,
                  icon: i18n.$t("NavBar").HistorySellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                  to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                  icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistorySellment.Label,
                  to: i18n.$t("NavBar").SelfHistorySellment.Link,
                  icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").History.Label,
                  to: i18n.$t("NavBar").History.Link,
                  icon: i18n.$t("NavBar").History.Logo,
                },
                {
                  label: i18n.$t("NavBar").HistoryPie.Label,
                  to: i18n.$t("NavBar").HistoryPie.Link,
                  icon: i18n.$t("NavBar").HistoryPie.Logo,
                },
                {
                  label: i18n.$t("NavBar").PaymentDetail.Label,
                  to: i18n.$t("NavBar").PaymentDetail.Link,
                  icon: i18n.$t("NavBar").PaymentDetail.Logo,
                },
              ],
            },
            {
              label: i18n.$t("NavBar").Setting.Label,
              icon: i18n.$t("NavBar").Setting.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").Organisations.Label,
                  to: i18n.$t("NavBar").Organisations.Link,
                  icon: i18n.$t("NavBar").Organisations.Logo,
                },
                {
                  label: i18n.$t("NavBar").Department.Label,
                  to: i18n.$t("NavBar").Department.Link,
                  icon: i18n.$t("NavBar").Department.Logo,
                },
                {
                  label: i18n.$t("NavBar").Nationality.Label,
                  to: i18n.$t("NavBar").Nationality.Link,
                  icon: i18n.$t("NavBar").Nationality.Logo,
                },
                {
                  label: i18n.$t("NavBar").Position.Label,
                  to: i18n.$t("NavBar").Position.Link,
                  icon: i18n.$t("NavBar").Position.Logo,
                },
                {
                  label: i18n.$t("NavBar").WorkPermit.Label,
                  to: i18n.$t("NavBar").WorkPermit.Link,
                  icon: i18n.$t("NavBar").WorkPermit.Logo,
                },
                {
                  label: i18n.$t("NavBar").ExchangeRate.Label,
                  to: i18n.$t("NavBar").ExchangeRate.Link,
                  icon: i18n.$t("NavBar").ExchangeRate.Logo,
                },
                {
                  label: i18n.$t("NavBar").Currency.Label,
                  to: i18n.$t("NavBar").Currency.Link,
                  icon: i18n.$t("NavBar").Currency.Logo,
                },
                {
                  label: i18n.$t("NavBar").SalaryItem.Label,
                  to: i18n.$t("NavBar").SalaryItem.Link,
                  icon: i18n.$t("NavBar").SalaryItem.Logo,
                },
                {
                  label: i18n.$t("NavBar").HolidayItem.Label,
                  to: i18n.$t("NavBar").HolidayItem.Link,
                  icon: i18n.$t("NavBar").HolidayItem.Logo,
                },
                {
                  label: i18n.$t("NavBar").Factory.Label,
                  to: i18n.$t("NavBar").Factory.Link,
                  icon: i18n.$t("NavBar").Factory.Logo,
                },
                {
                  label: i18n.$t("NavBar").Company.Label,
                  to: i18n.$t("NavBar").Company.Link,
                  icon: i18n.$t("NavBar").Company.Logo,
                },
                {
                  label: i18n.$t("NavBar").CompanyAccount.Label,
                  to: i18n.$t("NavBar").CompanyAccount.Link,
                  icon: i18n.$t("NavBar").CompanyAccount.Logo,
                },
                {
                  label: i18n.$t("NavBar").AccountType.Label,
                  to: i18n.$t("NavBar").AccountType.Link,
                  icon: i18n.$t("NavBar").AccountType.Logo,
                },
                {
                  label: i18n.$t("NavBar").AccountItem.Label,
                  to: i18n.$t("NavBar").AccountItem.Link,
                  icon: i18n.$t("NavBar").AccountItem.Logo,
                },
                {
                  label: i18n.$t("NavBar").PaymentItem.Label,
                  to: i18n.$t("NavBar").PaymentItem.Link,
                  icon: i18n.$t("NavBar").PaymentItem.Logo,
                },
                {
                  label: i18n.$t("NavBar").DocumentType.Label,
                  to: i18n.$t("NavBar").DocumentType.Link,
                  icon: i18n.$t("NavBar").DocumentType.Logo,
                },
              ],
            },
          ]
        : store.state.user.salary >= 200
        ? [
            {
              label: i18n.$t("NavBar").Document.Label,
              to: i18n.$t("NavBar").Document.Link,
              icon: i18n.$t("NavBar").Document.Logo,
            },
            {
              label: i18n.$t("NavBar").Info.Label,
              to: i18n.$t("NavBar").Info.Link,
              icon: i18n.$t("NavBar").Info.Logo,
            },
            {
              label: i18n.$t("NavBar").Salary.Label,
              icon: i18n.$t("NavBar").Salary.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").EmployerAdmin.Label,
                  to: i18n.$t("NavBar").EmployerAdmin.Link,
                  icon: i18n.$t("NavBar").EmployerAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                  to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                  icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
                  to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
                  icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
                  to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
                  icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
                  to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
                  icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
                  to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
                  icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
                  to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
                  icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplyBouns.Label,
                  to: i18n.$t("NavBar").ApplyBouns.Link,
                  icon: i18n.$t("NavBar").ApplyBouns.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditBounsAdmin.Label,
                  to: i18n.$t("NavBar").AuditBounsAdmin.Link,
                  icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").SalaryAdmin.Label,
                  to: i18n.$t("NavBar").SalaryAdmin.Link,
                  icon: i18n.$t("NavBar").SalaryAdmin.Logo,
                },
              ],
            },
            {
              label: i18n.$t("NavBar").PaymentSetting.Label,
              icon: i18n.$t("NavBar").PaymentSetting.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").Payment.Label,
                  to: i18n.$t("NavBar").Payment.Link,
                  icon: i18n.$t("NavBar").Payment.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditPayment.Label,
                  to: i18n.$t("NavBar").AuditPayment.Link,
                  icon: i18n.$t("NavBar").AuditPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplicationAmount.Label,
                  to: i18n.$t("NavBar").ApplicationAmount.Link,
                  icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditAmount.Label,
                  to: i18n.$t("NavBar").AuditAmount.Link,
                  icon: i18n.$t("NavBar").AuditAmount.Logo,
                },
                {
                  label: i18n.$t("NavBar").Sellment.Label,
                  to: i18n.$t("NavBar").Sellment.Link,
                  icon: i18n.$t("NavBar").Sellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditSellment.Label,
                  to: i18n.$t("NavBar").AuditSellment.Link,
                  icon: i18n.$t("NavBar").AuditSellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                  to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                  icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                },
                {
                  label: i18n.$t("NavBar").HistoryPayment.Label,
                  to: i18n.$t("NavBar").HistoryPayment.Link,
                  icon: i18n.$t("NavBar").HistoryPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").HistorySellment.Label,
                  to: i18n.$t("NavBar").HistorySellment.Link,
                  icon: i18n.$t("NavBar").HistorySellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                  to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                  icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistorySellment.Label,
                  to: i18n.$t("NavBar").SelfHistorySellment.Link,
                  icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").History.Label,
                  to: i18n.$t("NavBar").History.Link,
                  icon: i18n.$t("NavBar").History.Logo,
                },
                {
                  label: i18n.$t("NavBar").HistoryPie.Label,
                  to: i18n.$t("NavBar").HistoryPie.Link,
                  icon: i18n.$t("NavBar").HistoryPie.Logo,
                },
                {
                  label: i18n.$t("NavBar").PaymentDetail.Label,
                  to: i18n.$t("NavBar").PaymentDetail.Link,
                  icon: i18n.$t("NavBar").PaymentDetail.Logo,
                },
              ],
            },
            {
              label: i18n.$t("NavBar").Setting.Label,
              icon: i18n.$t("NavBar").Setting.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").Organisations.Label,
                  to: i18n.$t("NavBar").Organisations.Link,
                  icon: i18n.$t("NavBar").Organisations.Logo,
                },
                {
                  label: i18n.$t("NavBar").Department.Label,
                  to: i18n.$t("NavBar").Department.Link,
                  icon: i18n.$t("NavBar").Department.Logo,
                },
                {
                  label: i18n.$t("NavBar").Nationality.Label,
                  to: i18n.$t("NavBar").Nationality.Link,
                  icon: i18n.$t("NavBar").Nationality.Logo,
                },
                {
                  label: i18n.$t("NavBar").Position.Label,
                  to: i18n.$t("NavBar").Position.Link,
                  icon: i18n.$t("NavBar").Position.Logo,
                },
                {
                  label: i18n.$t("NavBar").WorkPermit.Label,
                  to: i18n.$t("NavBar").WorkPermit.Link,
                  icon: i18n.$t("NavBar").WorkPermit.Logo,
                },
                {
                  label: i18n.$t("NavBar").ExchangeRate.Label,
                  to: i18n.$t("NavBar").ExchangeRate.Link,
                  icon: i18n.$t("NavBar").ExchangeRate.Logo,
                },
                {
                  label: i18n.$t("NavBar").Currency.Label,
                  to: i18n.$t("NavBar").Currency.Link,
                  icon: i18n.$t("NavBar").Currency.Logo,
                },
                {
                  label: i18n.$t("NavBar").SalaryItem.Label,
                  to: i18n.$t("NavBar").SalaryItem.Link,
                  icon: i18n.$t("NavBar").SalaryItem.Logo,
                },
                {
                  label: i18n.$t("NavBar").HolidayItem.Label,
                  to: i18n.$t("NavBar").HolidayItem.Link,
                  icon: i18n.$t("NavBar").HolidayItem.Logo,
                },
                {
                  label: i18n.$t("NavBar").Factory.Label,
                  to: i18n.$t("NavBar").Factory.Link,
                  icon: i18n.$t("NavBar").Factory.Logo,
                },
                {
                  label: i18n.$t("NavBar").Company.Label,
                  to: i18n.$t("NavBar").Company.Link,
                  icon: i18n.$t("NavBar").Company.Logo,
                },
                {
                  label: i18n.$t("NavBar").CompanyAccount.Label,
                  to: i18n.$t("NavBar").CompanyAccount.Link,
                  icon: i18n.$t("NavBar").CompanyAccount.Logo,
                },
                {
                  label: i18n.$t("NavBar").AccountType.Label,
                  to: i18n.$t("NavBar").AccountType.Link,
                  icon: i18n.$t("NavBar").AccountType.Logo,
                },
                {
                  label: i18n.$t("NavBar").AccountItem.Label,
                  to: i18n.$t("NavBar").AccountItem.Link,
                  icon: i18n.$t("NavBar").AccountItem.Logo,
                },
                {
                  label: i18n.$t("NavBar").PaymentItem.Label,
                  to: i18n.$t("NavBar").PaymentItem.Link,
                  icon: i18n.$t("NavBar").PaymentItem.Logo,
                },
                {
                  label: i18n.$t("NavBar").DocumentType.Label,
                  to: i18n.$t("NavBar").DocumentType.Link,
                  icon: i18n.$t("NavBar").DocumentType.Logo,
                },
              ],
            },
          ]
        : [
            {
              label: i18n.$t("NavBar").Info.Label,
              to: i18n.$t("NavBar").Info.Link,
              icon: i18n.$t("NavBar").Info.Logo,
            },
            {
              label: i18n.$t("NavBar").Salary.Label,
              icon: i18n.$t("NavBar").Salary.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").EmployerAdmin.Label,
                  to: i18n.$t("NavBar").EmployerAdmin.Link,
                  icon: i18n.$t("NavBar").EmployerAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                  to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                  icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
                  to: i18n.$t("NavBar").ApplySalaryAdmin.Link,
                  icon: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
                  to: i18n.$t("NavBar").AuditSalaryAdmin.Link,
                  icon: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
                  to: i18n.$t("NavBar").AuditHolidayAdmin.Link,
                  icon: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
                  to: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
                  icon: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
                  to: i18n.$t("NavBar").AuditBorrowAdmin.Link,
                  icon: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplyBouns.Label,
                  to: i18n.$t("NavBar").ApplyBouns.Link,
                  icon: i18n.$t("NavBar").ApplyBouns.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditBounsAdmin.Label,
                  to: i18n.$t("NavBar").AuditBounsAdmin.Link,
                  icon: i18n.$t("NavBar").AuditBounsAdmin.Logo,
                },
                {
                  label: i18n.$t("NavBar").SalaryAdmin.Label,
                  to: i18n.$t("NavBar").SalaryAdmin.Link,
                  icon: i18n.$t("NavBar").SalaryAdmin.Logo,
                },
              ],
            },
            {
              label: i18n.$t("NavBar").PaymentSetting.Label,
              icon: i18n.$t("NavBar").PaymentSetting.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").Payment.Label,
                  to: i18n.$t("NavBar").Payment.Link,
                  icon: i18n.$t("NavBar").Payment.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditPayment.Label,
                  to: i18n.$t("NavBar").AuditPayment.Link,
                  icon: i18n.$t("NavBar").AuditPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplicationAmount.Label,
                  to: i18n.$t("NavBar").ApplicationAmount.Link,
                  icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditAmount.Label,
                  to: i18n.$t("NavBar").AuditAmount.Link,
                  icon: i18n.$t("NavBar").AuditAmount.Logo,
                },
                {
                  label: i18n.$t("NavBar").Sellment.Label,
                  to: i18n.$t("NavBar").Sellment.Link,
                  icon: i18n.$t("NavBar").Sellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditSellment.Label,
                  to: i18n.$t("NavBar").AuditSellment.Link,
                  icon: i18n.$t("NavBar").AuditSellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").HistoryPaymentAll.Label,
                  to: i18n.$t("NavBar").HistoryPaymentAll.Link,
                  icon: i18n.$t("NavBar").HistoryPaymentAll.Logo,
                },
                {
                  label: i18n.$t("NavBar").HistoryPayment.Label,
                  to: i18n.$t("NavBar").HistoryPayment.Link,
                  icon: i18n.$t("NavBar").HistoryPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").HistorySellment.Label,
                  to: i18n.$t("NavBar").HistorySellment.Link,
                  icon: i18n.$t("NavBar").HistorySellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                  to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                  icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistorySellment.Label,
                  to: i18n.$t("NavBar").SelfHistorySellment.Link,
                  icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").History.Label,
                  to: i18n.$t("NavBar").History.Link,
                  icon: i18n.$t("NavBar").History.Logo,
                },
                {
                  label: i18n.$t("NavBar").HistoryPie.Label,
                  to: i18n.$t("NavBar").HistoryPie.Link,
                  icon: i18n.$t("NavBar").HistoryPie.Logo,
                },
                {
                  label: i18n.$t("NavBar").PaymentDetail.Label,
                  to: i18n.$t("NavBar").PaymentDetail.Link,
                  icon: i18n.$t("NavBar").PaymentDetail.Logo,
                },
              ],
            },
            {
              label: i18n.$t("NavBar").Setting.Label,
              icon: i18n.$t("NavBar").Setting.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").Organisations.Label,
                  to: i18n.$t("NavBar").Organisations.Link,
                  icon: i18n.$t("NavBar").Organisations.Logo,
                },
                {
                  label: i18n.$t("NavBar").Department.Label,
                  to: i18n.$t("NavBar").Department.Link,
                  icon: i18n.$t("NavBar").Department.Logo,
                },
                {
                  label: i18n.$t("NavBar").Nationality.Label,
                  to: i18n.$t("NavBar").Nationality.Link,
                  icon: i18n.$t("NavBar").Nationality.Logo,
                },
                {
                  label: i18n.$t("NavBar").Position.Label,
                  to: i18n.$t("NavBar").Position.Link,
                  icon: i18n.$t("NavBar").Position.Logo,
                },
                {
                  label: i18n.$t("NavBar").WorkPermit.Label,
                  to: i18n.$t("NavBar").WorkPermit.Link,
                  icon: i18n.$t("NavBar").WorkPermit.Logo,
                },
                {
                  label: i18n.$t("NavBar").ExchangeRate.Label,
                  to: i18n.$t("NavBar").ExchangeRate.Link,
                  icon: i18n.$t("NavBar").ExchangeRate.Logo,
                },
                {
                  label: i18n.$t("NavBar").Currency.Label,
                  to: i18n.$t("NavBar").Currency.Link,
                  icon: i18n.$t("NavBar").Currency.Logo,
                },
                {
                  label: i18n.$t("NavBar").SalaryItem.Label,
                  to: i18n.$t("NavBar").SalaryItem.Link,
                  icon: i18n.$t("NavBar").SalaryItem.Logo,
                },
                {
                  label: i18n.$t("NavBar").HolidayItem.Label,
                  to: i18n.$t("NavBar").HolidayItem.Link,
                  icon: i18n.$t("NavBar").HolidayItem.Logo,
                },
                {
                  label: i18n.$t("NavBar").Factory.Label,
                  to: i18n.$t("NavBar").Factory.Link,
                  icon: i18n.$t("NavBar").Factory.Logo,
                },
                {
                  label: i18n.$t("NavBar").Company.Label,
                  to: i18n.$t("NavBar").Company.Link,
                  icon: i18n.$t("NavBar").Company.Logo,
                },
                {
                  label: i18n.$t("NavBar").CompanyAccount.Label,
                  to: i18n.$t("NavBar").CompanyAccount.Link,
                  icon: i18n.$t("NavBar").CompanyAccount.Logo,
                },
                {
                  label: i18n.$t("NavBar").AccountType.Label,
                  to: i18n.$t("NavBar").AccountType.Link,
                  icon: i18n.$t("NavBar").AccountType.Logo,
                },
                {
                  label: i18n.$t("NavBar").AccountItem.Label,
                  to: i18n.$t("NavBar").AccountItem.Link,
                  icon: i18n.$t("NavBar").AccountItem.Logo,
                },
                {
                  label: i18n.$t("NavBar").PaymentItem.Label,
                  to: i18n.$t("NavBar").PaymentItem.Link,
                  icon: i18n.$t("NavBar").PaymentItem.Logo,
                },
                {
                  label: i18n.$t("NavBar").DocumentType.Label,
                  to: i18n.$t("NavBar").DocumentType.Link,
                  icon: i18n.$t("NavBar").DocumentType.Logo,
                },
              ],
            },
          ]
      : store.state.user.role == 2
      ? store.state.user.role_addition_binding == 201
        ? store.state.user.organisation_id == 1
          ? [
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              {
                label: i18n.$t("NavBar").Salary.Label,
                icon: i18n.$t("NavBar").Salary.Logo,
                items:
                  store.state.user.receipt_type == 1
                    ? [
                        {
                          label:
                            i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                          to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                          icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyHoliday.Label,
                          to: i18n.$t("NavBar").ApplyHoliday.Link,
                          icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                          to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                          icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyBouns.Label,
                          to: i18n.$t("NavBar").ApplyBouns.Link,
                          icon: i18n.$t("NavBar").ApplyBouns.Logo,
                        },
                        // {
                        //   label: i18n.$t("NavBar").EmployerManager.Label,
                        //   to: i18n.$t("NavBar").EmployerManager.Link,
                        //   icon: i18n.$t("NavBar").EmployerManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").ApplySalaryManager.Label,
                        //   to: i18n.$t("NavBar").ApplySalaryManager.Link,
                        //   icon: i18n.$t("NavBar").ApplySalaryManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditHolidayManager.Label,
                        //   to: i18n.$t("NavBar").AuditHolidayManager.Link,
                        //   icon: i18n.$t("NavBar").AuditHolidayManager.Logo,
                        // },
                        // {
                        //   label:
                        //     i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
                        //   to: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
                        //   icon: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditBorrowManager.Label,
                        //   to: i18n.$t("NavBar").AuditBorrowManager.Link,
                        //   icon: i18n.$t("NavBar").AuditBorrowManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditBounsManager.Label,
                        //   to: i18n.$t("NavBar").AuditBounsManager.Link,
                        //   icon: i18n.$t("NavBar").AuditBounsManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").SalaryManager.Label,
                        //   to: i18n.$t("NavBar").SalaryManager.Link,
                        //   icon: i18n.$t("NavBar").SalaryManager.Logo,
                        // },
                      ]
                    : [
                        {
                          label:
                            i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                          to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                          icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyHoliday.Label,
                          to: i18n.$t("NavBar").ApplyHoliday.Link,
                          icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                          to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                          icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyBorrow.Label,
                          to: i18n.$t("NavBar").ApplyBorrow.Link,
                          icon: i18n.$t("NavBar").ApplyBorrow.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyBouns.Label,
                          to: i18n.$t("NavBar").ApplyBouns.Link,
                          icon: i18n.$t("NavBar").ApplyBouns.Logo,
                        },
                        // {
                        //   label: i18n.$t("NavBar").EmployerManager.Label,
                        //   to: i18n.$t("NavBar").EmployerManager.Link,
                        //   icon: i18n.$t("NavBar").EmployerManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").ApplySalaryManager.Label,
                        //   to: i18n.$t("NavBar").ApplySalaryManager.Link,
                        //   icon: i18n.$t("NavBar").ApplySalaryManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditHolidayManager.Label,
                        //   to: i18n.$t("NavBar").AuditHolidayManager.Link,
                        //   icon: i18n.$t("NavBar").AuditHolidayManager.Logo,
                        // },
                        // {
                        //   label:
                        //     i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
                        //   to: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
                        //   icon: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditBorrowManager.Label,
                        //   to: i18n.$t("NavBar").AuditBorrowManager.Link,
                        //   icon: i18n.$t("NavBar").AuditBorrowManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditBounsManager.Label,
                        //   to: i18n.$t("NavBar").AuditBounsManager.Link,
                        //   icon: i18n.$t("NavBar").AuditBounsManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").SalaryManager.Label,
                        //   to: i18n.$t("NavBar").SalaryManager.Link,
                        //   icon: i18n.$t("NavBar").SalaryManager.Logo,
                        // },
                      ],
              },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditPayment.Label,
                    to: i18n.$t("NavBar").AuditPayment.Link,
                    icon: i18n.$t("NavBar").AuditPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  // {
                  //   label: i18n.$t("NavBar").AuditAmount.Label,
                  //   to: i18n.$t("NavBar").AuditAmount.Link,
                  //   icon: i18n.$t("NavBar").AuditAmount.Logo,
                  // },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                  //////////////
                  // {
                  //   label: i18n.$t("NavBar").AuditSellment.Label,
                  //   to: i18n.$t("NavBar").AuditSellment.Link,
                  //   icon: i18n.$t("NavBar").AuditSellment.Logo,
                  // },
                ],
              },
            ]
          : store.state.user.salary >= 200
          ? [
              {
                label: i18n.$t("NavBar").Document.Label,
                to: i18n.$t("NavBar").Document.Link,
                icon: i18n.$t("NavBar").Document.Logo,
              },
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              {
                label: i18n.$t("NavBar").Salary.Label,
                icon: i18n.$t("NavBar").Salary.Logo,
                items:
                  store.state.user.receipt_type == 1
                    ? [
                        {
                          label:
                            i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                          to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                          icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyHoliday.Label,
                          to: i18n.$t("NavBar").ApplyHoliday.Link,
                          icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                          to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                          icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyBouns.Label,
                          to: i18n.$t("NavBar").ApplyBouns.Link,
                          icon: i18n.$t("NavBar").ApplyBouns.Logo,
                        },
                        // {
                        //   label: i18n.$t("NavBar").EmployerManager.Label,
                        //   to: i18n.$t("NavBar").EmployerManager.Link,
                        //   icon: i18n.$t("NavBar").EmployerManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").ApplySalaryManager.Label,
                        //   to: i18n.$t("NavBar").ApplySalaryManager.Link,
                        //   icon: i18n.$t("NavBar").ApplySalaryManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditHolidayManager.Label,
                        //   to: i18n.$t("NavBar").AuditHolidayManager.Link,
                        //   icon: i18n.$t("NavBar").AuditHolidayManager.Logo,
                        // },
                        // {
                        //   label:
                        //     i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
                        //   to: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
                        //   icon: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditBorrowManager.Label,
                        //   to: i18n.$t("NavBar").AuditBorrowManager.Link,
                        //   icon: i18n.$t("NavBar").AuditBorrowManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditBounsManager.Label,
                        //   to: i18n.$t("NavBar").AuditBounsManager.Link,
                        //   icon: i18n.$t("NavBar").AuditBounsManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").SalaryManager.Label,
                        //   to: i18n.$t("NavBar").SalaryManager.Link,
                        //   icon: i18n.$t("NavBar").SalaryManager.Logo,
                        // },
                      ]
                    : [
                        {
                          label:
                            i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                          to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                          icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyHoliday.Label,
                          to: i18n.$t("NavBar").ApplyHoliday.Link,
                          icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                          to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                          icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyBorrow.Label,
                          to: i18n.$t("NavBar").ApplyBorrow.Link,
                          icon: i18n.$t("NavBar").ApplyBorrow.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyBouns.Label,
                          to: i18n.$t("NavBar").ApplyBouns.Link,
                          icon: i18n.$t("NavBar").ApplyBouns.Logo,
                        },
                        // {
                        //   label: i18n.$t("NavBar").EmployerManager.Label,
                        //   to: i18n.$t("NavBar").EmployerManager.Link,
                        //   icon: i18n.$t("NavBar").EmployerManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").ApplySalaryManager.Label,
                        //   to: i18n.$t("NavBar").ApplySalaryManager.Link,
                        //   icon: i18n.$t("NavBar").ApplySalaryManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditHolidayManager.Label,
                        //   to: i18n.$t("NavBar").AuditHolidayManager.Link,
                        //   icon: i18n.$t("NavBar").AuditHolidayManager.Logo,
                        // },
                        // {
                        //   label:
                        //     i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
                        //   to: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
                        //   icon: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditBorrowManager.Label,
                        //   to: i18n.$t("NavBar").AuditBorrowManager.Link,
                        //   icon: i18n.$t("NavBar").AuditBorrowManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditBounsManager.Label,
                        //   to: i18n.$t("NavBar").AuditBounsManager.Link,
                        //   icon: i18n.$t("NavBar").AuditBounsManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").SalaryManager.Label,
                        //   to: i18n.$t("NavBar").SalaryManager.Link,
                        //   icon: i18n.$t("NavBar").SalaryManager.Logo,
                        // },
                      ],
              },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditPayment.Label,
                    to: i18n.$t("NavBar").AuditPayment.Link,
                    icon: i18n.$t("NavBar").AuditPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  // {
                  //   label: i18n.$t("NavBar").AuditAmount.Label,
                  //   to: i18n.$t("NavBar").AuditAmount.Link,
                  //   icon: i18n.$t("NavBar").AuditAmount.Logo,
                  // },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                  //////////////
                  // {
                  //   label: i18n.$t("NavBar").AuditSellment.Label,
                  //   to: i18n.$t("NavBar").AuditSellment.Link,
                  //   icon: i18n.$t("NavBar").AuditSellment.Logo,
                  // },
                ],
              },
            ]
          : [
              {
                label: i18n.$t("NavBar").Info.Label,
                to: i18n.$t("NavBar").Info.Link,
                icon: i18n.$t("NavBar").Info.Logo,
              },
              {
                label: i18n.$t("NavBar").Salary.Label,
                icon: i18n.$t("NavBar").Salary.Logo,
                items:
                  store.state.user.receipt_type == 1
                    ? [
                        {
                          label:
                            i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                          to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                          icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyHoliday.Label,
                          to: i18n.$t("NavBar").ApplyHoliday.Link,
                          icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                          to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                          icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyBouns.Label,
                          to: i18n.$t("NavBar").ApplyBouns.Link,
                          icon: i18n.$t("NavBar").ApplyBouns.Logo,
                        },
                        // {
                        //   label: i18n.$t("NavBar").EmployerManager.Label,
                        //   to: i18n.$t("NavBar").EmployerManager.Link,
                        //   icon: i18n.$t("NavBar").EmployerManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").ApplySalaryManager.Label,
                        //   to: i18n.$t("NavBar").ApplySalaryManager.Link,
                        //   icon: i18n.$t("NavBar").ApplySalaryManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditHolidayManager.Label,
                        //   to: i18n.$t("NavBar").AuditHolidayManager.Link,
                        //   icon: i18n.$t("NavBar").AuditHolidayManager.Logo,
                        // },
                        // {
                        //   label:
                        //     i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
                        //   to: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
                        //   icon: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditBorrowManager.Label,
                        //   to: i18n.$t("NavBar").AuditBorrowManager.Link,
                        //   icon: i18n.$t("NavBar").AuditBorrowManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditBounsManager.Label,
                        //   to: i18n.$t("NavBar").AuditBounsManager.Link,
                        //   icon: i18n.$t("NavBar").AuditBounsManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").SalaryManager.Label,
                        //   to: i18n.$t("NavBar").SalaryManager.Link,
                        //   icon: i18n.$t("NavBar").SalaryManager.Logo,
                        // },
                      ]
                    : [
                        {
                          label:
                            i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                          to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                          icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyHoliday.Label,
                          to: i18n.$t("NavBar").ApplyHoliday.Link,
                          icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                          to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                          icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyBorrow.Label,
                          to: i18n.$t("NavBar").ApplyBorrow.Link,
                          icon: i18n.$t("NavBar").ApplyBorrow.Logo,
                        },
                        {
                          label: i18n.$t("NavBar").ApplyBouns.Label,
                          to: i18n.$t("NavBar").ApplyBouns.Link,
                          icon: i18n.$t("NavBar").ApplyBouns.Logo,
                        },
                        // {
                        //   label: i18n.$t("NavBar").EmployerManager.Label,
                        //   to: i18n.$t("NavBar").EmployerManager.Link,
                        //   icon: i18n.$t("NavBar").EmployerManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").ApplySalaryManager.Label,
                        //   to: i18n.$t("NavBar").ApplySalaryManager.Link,
                        //   icon: i18n.$t("NavBar").ApplySalaryManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditHolidayManager.Label,
                        //   to: i18n.$t("NavBar").AuditHolidayManager.Link,
                        //   icon: i18n.$t("NavBar").AuditHolidayManager.Logo,
                        // },
                        // {
                        //   label:
                        //     i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
                        //   to: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
                        //   icon: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditBorrowManager.Label,
                        //   to: i18n.$t("NavBar").AuditBorrowManager.Link,
                        //   icon: i18n.$t("NavBar").AuditBorrowManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").AuditBounsManager.Label,
                        //   to: i18n.$t("NavBar").AuditBounsManager.Link,
                        //   icon: i18n.$t("NavBar").AuditBounsManager.Logo,
                        // },
                        // {
                        //   label: i18n.$t("NavBar").SalaryManager.Label,
                        //   to: i18n.$t("NavBar").SalaryManager.Link,
                        //   icon: i18n.$t("NavBar").SalaryManager.Logo,
                        // },
                      ],
              },
              {
                label: i18n.$t("NavBar").PaymentSetting.Label,
                icon: i18n.$t("NavBar").PaymentSetting.Logo,
                items: [
                  {
                    label: i18n.$t("NavBar").Payment.Label,
                    to: i18n.$t("NavBar").Payment.Link,
                    icon: i18n.$t("NavBar").Payment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").AuditPayment.Label,
                    to: i18n.$t("NavBar").AuditPayment.Link,
                    icon: i18n.$t("NavBar").AuditPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").ApplicationAmount.Label,
                    to: i18n.$t("NavBar").ApplicationAmount.Link,
                    icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                  },
                  // {
                  //   label: i18n.$t("NavBar").AuditAmount.Label,
                  //   to: i18n.$t("NavBar").AuditAmount.Link,
                  //   icon: i18n.$t("NavBar").AuditAmount.Logo,
                  // },
                  {
                    label: i18n.$t("NavBar").Sellment.Label,
                    to: i18n.$t("NavBar").Sellment.Link,
                    icon: i18n.$t("NavBar").Sellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                    to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                    icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").SelfHistorySellment.Label,
                    to: i18n.$t("NavBar").SelfHistorySellment.Link,
                    icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                  },
                  {
                    label: i18n.$t("NavBar").PaymentDetail.Label,
                    to: i18n.$t("NavBar").PaymentDetail.Link,
                    icon: i18n.$t("NavBar").PaymentDetail.Logo,
                  },
                  //////////////
                  // {
                  //   label: i18n.$t("NavBar").AuditSellment.Label,
                  //   to: i18n.$t("NavBar").AuditSellment.Link,
                  //   icon: i18n.$t("NavBar").AuditSellment.Logo,
                  // },
                ],
              },
            ]
        : store.state.user.organisation_id == 1
        ? [
            {
              label: i18n.$t("NavBar").Info.Label,
              to: i18n.$t("NavBar").Info.Link,
              icon: i18n.$t("NavBar").Info.Logo,
            },
            {
              label: i18n.$t("NavBar").Salary.Label,
              icon: i18n.$t("NavBar").Salary.Logo,
              items:
                store.state.user.receipt_type == 1
                  ? [
                      {
                        label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                        to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                        icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyHoliday.Label,
                        to: i18n.$t("NavBar").ApplyHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                        to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBouns.Label,
                        to: i18n.$t("NavBar").ApplyBouns.Link,
                        icon: i18n.$t("NavBar").ApplyBouns.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").EmployerManager.Label,
                        to: i18n.$t("NavBar").EmployerManager.Link,
                        icon: i18n.$t("NavBar").EmployerManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplySalaryManager.Label,
                        to: i18n.$t("NavBar").ApplySalaryManager.Link,
                        icon: i18n.$t("NavBar").ApplySalaryManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditHolidayManager.Label,
                        to: i18n.$t("NavBar").AuditHolidayManager.Link,
                        icon: i18n.$t("NavBar").AuditHolidayManager.Logo,
                      },
                      {
                        label:
                          i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
                        to: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
                        icon: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditBorrowManager.Label,
                        to: i18n.$t("NavBar").AuditBorrowManager.Link,
                        icon: i18n.$t("NavBar").AuditBorrowManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditBounsManager.Label,
                        to: i18n.$t("NavBar").AuditBounsManager.Link,
                        icon: i18n.$t("NavBar").AuditBounsManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").SalaryManager.Label,
                        to: i18n.$t("NavBar").SalaryManager.Link,
                        icon: i18n.$t("NavBar").SalaryManager.Logo,
                      },
                    ]
                  : [
                      {
                        label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                        to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                        icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyHoliday.Label,
                        to: i18n.$t("NavBar").ApplyHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                        to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBorrow.Label,
                        to: i18n.$t("NavBar").ApplyBorrow.Link,
                        icon: i18n.$t("NavBar").ApplyBorrow.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBouns.Label,
                        to: i18n.$t("NavBar").ApplyBouns.Link,
                        icon: i18n.$t("NavBar").ApplyBouns.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").EmployerManager.Label,
                        to: i18n.$t("NavBar").EmployerManager.Link,
                        icon: i18n.$t("NavBar").EmployerManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplySalaryManager.Label,
                        to: i18n.$t("NavBar").ApplySalaryManager.Link,
                        icon: i18n.$t("NavBar").ApplySalaryManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditHolidayManager.Label,
                        to: i18n.$t("NavBar").AuditHolidayManager.Link,
                        icon: i18n.$t("NavBar").AuditHolidayManager.Logo,
                      },
                      {
                        label:
                          i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
                        to: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
                        icon: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditBorrowManager.Label,
                        to: i18n.$t("NavBar").AuditBorrowManager.Link,
                        icon: i18n.$t("NavBar").AuditBorrowManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditBounsManager.Label,
                        to: i18n.$t("NavBar").AuditBounsManager.Link,
                        icon: i18n.$t("NavBar").AuditBounsManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").SalaryManager.Label,
                        to: i18n.$t("NavBar").SalaryManager.Link,
                        icon: i18n.$t("NavBar").SalaryManager.Logo,
                      },
                    ],
            },
            {
              label: i18n.$t("NavBar").PaymentSetting.Label,
              icon: i18n.$t("NavBar").PaymentSetting.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").Payment.Label,
                  to: i18n.$t("NavBar").Payment.Link,
                  icon: i18n.$t("NavBar").Payment.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditPayment.Label,
                  to: i18n.$t("NavBar").AuditPayment.Link,
                  icon: i18n.$t("NavBar").AuditPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplicationAmount.Label,
                  to: i18n.$t("NavBar").ApplicationAmount.Link,
                  icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                },
                // {
                //   label: i18n.$t("NavBar").AuditAmount.Label,
                //   to: i18n.$t("NavBar").AuditAmount.Link,
                //   icon: i18n.$t("NavBar").AuditAmount.Logo,
                // },
                {
                  label: i18n.$t("NavBar").Sellment.Label,
                  to: i18n.$t("NavBar").Sellment.Link,
                  icon: i18n.$t("NavBar").Sellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                  to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                  icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistorySellment.Label,
                  to: i18n.$t("NavBar").SelfHistorySellment.Link,
                  icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").PaymentDetail.Label,
                  to: i18n.$t("NavBar").PaymentDetail.Link,
                  icon: i18n.$t("NavBar").PaymentDetail.Logo,
                },
                //////////////
                // {
                //   label: i18n.$t("NavBar").AuditSellment.Label,
                //   to: i18n.$t("NavBar").AuditSellment.Link,
                //   icon: i18n.$t("NavBar").AuditSellment.Logo,
                // },
              ],
            },
          ]
        : store.state.user.salary >= 200
        ? [
            {
              label: i18n.$t("NavBar").Document.Label,
              to: i18n.$t("NavBar").Document.Link,
              icon: i18n.$t("NavBar").Document.Logo,
            },
            {
              label: i18n.$t("NavBar").Info.Label,
              to: i18n.$t("NavBar").Info.Link,
              icon: i18n.$t("NavBar").Info.Logo,
            },
            {
              label: i18n.$t("NavBar").Salary.Label,
              icon: i18n.$t("NavBar").Salary.Logo,
              items:
                store.state.user.receipt_type == 1
                  ? [
                      {
                        label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                        to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                        icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyHoliday.Label,
                        to: i18n.$t("NavBar").ApplyHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                        to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBouns.Label,
                        to: i18n.$t("NavBar").ApplyBouns.Link,
                        icon: i18n.$t("NavBar").ApplyBouns.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").EmployerManager.Label,
                        to: i18n.$t("NavBar").EmployerManager.Link,
                        icon: i18n.$t("NavBar").EmployerManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplySalaryManager.Label,
                        to: i18n.$t("NavBar").ApplySalaryManager.Link,
                        icon: i18n.$t("NavBar").ApplySalaryManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditHolidayManager.Label,
                        to: i18n.$t("NavBar").AuditHolidayManager.Link,
                        icon: i18n.$t("NavBar").AuditHolidayManager.Logo,
                      },
                      {
                        label:
                          i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
                        to: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
                        icon: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditBorrowManager.Label,
                        to: i18n.$t("NavBar").AuditBorrowManager.Link,
                        icon: i18n.$t("NavBar").AuditBorrowManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditBounsManager.Label,
                        to: i18n.$t("NavBar").AuditBounsManager.Link,
                        icon: i18n.$t("NavBar").AuditBounsManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").SalaryManager.Label,
                        to: i18n.$t("NavBar").SalaryManager.Link,
                        icon: i18n.$t("NavBar").SalaryManager.Logo,
                      },
                    ]
                  : [
                      {
                        label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                        to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                        icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyHoliday.Label,
                        to: i18n.$t("NavBar").ApplyHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                        to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBorrow.Label,
                        to: i18n.$t("NavBar").ApplyBorrow.Link,
                        icon: i18n.$t("NavBar").ApplyBorrow.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBouns.Label,
                        to: i18n.$t("NavBar").ApplyBouns.Link,
                        icon: i18n.$t("NavBar").ApplyBouns.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").EmployerManager.Label,
                        to: i18n.$t("NavBar").EmployerManager.Link,
                        icon: i18n.$t("NavBar").EmployerManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplySalaryManager.Label,
                        to: i18n.$t("NavBar").ApplySalaryManager.Link,
                        icon: i18n.$t("NavBar").ApplySalaryManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditHolidayManager.Label,
                        to: i18n.$t("NavBar").AuditHolidayManager.Link,
                        icon: i18n.$t("NavBar").AuditHolidayManager.Logo,
                      },
                      {
                        label:
                          i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
                        to: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
                        icon: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditBorrowManager.Label,
                        to: i18n.$t("NavBar").AuditBorrowManager.Link,
                        icon: i18n.$t("NavBar").AuditBorrowManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditBounsManager.Label,
                        to: i18n.$t("NavBar").AuditBounsManager.Link,
                        icon: i18n.$t("NavBar").AuditBounsManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").SalaryManager.Label,
                        to: i18n.$t("NavBar").SalaryManager.Link,
                        icon: i18n.$t("NavBar").SalaryManager.Logo,
                      },
                    ],
            },
            {
              label: i18n.$t("NavBar").PaymentSetting.Label,
              icon: i18n.$t("NavBar").PaymentSetting.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").Payment.Label,
                  to: i18n.$t("NavBar").Payment.Link,
                  icon: i18n.$t("NavBar").Payment.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditPayment.Label,
                  to: i18n.$t("NavBar").AuditPayment.Link,
                  icon: i18n.$t("NavBar").AuditPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplicationAmount.Label,
                  to: i18n.$t("NavBar").ApplicationAmount.Link,
                  icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                },
                // {
                //   label: i18n.$t("NavBar").AuditAmount.Label,
                //   to: i18n.$t("NavBar").AuditAmount.Link,
                //   icon: i18n.$t("NavBar").AuditAmount.Logo,
                // },
                {
                  label: i18n.$t("NavBar").Sellment.Label,
                  to: i18n.$t("NavBar").Sellment.Link,
                  icon: i18n.$t("NavBar").Sellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                  to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                  icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistorySellment.Label,
                  to: i18n.$t("NavBar").SelfHistorySellment.Link,
                  icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").PaymentDetail.Label,
                  to: i18n.$t("NavBar").PaymentDetail.Link,
                  icon: i18n.$t("NavBar").PaymentDetail.Logo,
                },
                //////////////
                // {
                //   label: i18n.$t("NavBar").AuditSellment.Label,
                //   to: i18n.$t("NavBar").AuditSellment.Link,
                //   icon: i18n.$t("NavBar").AuditSellment.Logo,
                // },
              ],
            },
          ]
        : [
            {
              label: i18n.$t("NavBar").Info.Label,
              to: i18n.$t("NavBar").Info.Link,
              icon: i18n.$t("NavBar").Info.Logo,
            },
            {
              label: i18n.$t("NavBar").Salary.Label,
              icon: i18n.$t("NavBar").Salary.Logo,
              items:
                store.state.user.receipt_type == 1
                  ? [
                      {
                        label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                        to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                        icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyHoliday.Label,
                        to: i18n.$t("NavBar").ApplyHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                        to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBouns.Label,
                        to: i18n.$t("NavBar").ApplyBouns.Link,
                        icon: i18n.$t("NavBar").ApplyBouns.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").EmployerManager.Label,
                        to: i18n.$t("NavBar").EmployerManager.Link,
                        icon: i18n.$t("NavBar").EmployerManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplySalaryManager.Label,
                        to: i18n.$t("NavBar").ApplySalaryManager.Link,
                        icon: i18n.$t("NavBar").ApplySalaryManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditHolidayManager.Label,
                        to: i18n.$t("NavBar").AuditHolidayManager.Link,
                        icon: i18n.$t("NavBar").AuditHolidayManager.Logo,
                      },
                      {
                        label:
                          i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
                        to: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
                        icon: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditBorrowManager.Label,
                        to: i18n.$t("NavBar").AuditBorrowManager.Link,
                        icon: i18n.$t("NavBar").AuditBorrowManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditBounsManager.Label,
                        to: i18n.$t("NavBar").AuditBounsManager.Link,
                        icon: i18n.$t("NavBar").AuditBounsManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").SalaryManager.Label,
                        to: i18n.$t("NavBar").SalaryManager.Link,
                        icon: i18n.$t("NavBar").SalaryManager.Logo,
                      },
                    ]
                  : [
                      {
                        label: i18n.$t("NavBar").EmployerAdminImageExpire.Label,
                        to: i18n.$t("NavBar").EmployerAdminImageExpire.Link,
                        icon: i18n.$t("NavBar").EmployerAdminImageExpire.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyHoliday.Label,
                        to: i18n.$t("NavBar").ApplyHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                        to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBorrow.Label,
                        to: i18n.$t("NavBar").ApplyBorrow.Link,
                        icon: i18n.$t("NavBar").ApplyBorrow.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBouns.Label,
                        to: i18n.$t("NavBar").ApplyBouns.Link,
                        icon: i18n.$t("NavBar").ApplyBouns.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").EmployerManager.Label,
                        to: i18n.$t("NavBar").EmployerManager.Link,
                        icon: i18n.$t("NavBar").EmployerManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplySalaryManager.Label,
                        to: i18n.$t("NavBar").ApplySalaryManager.Link,
                        icon: i18n.$t("NavBar").ApplySalaryManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditHolidayManager.Label,
                        to: i18n.$t("NavBar").AuditHolidayManager.Link,
                        icon: i18n.$t("NavBar").AuditHolidayManager.Logo,
                      },
                      {
                        label:
                          i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
                        to: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
                        icon: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditBorrowManager.Label,
                        to: i18n.$t("NavBar").AuditBorrowManager.Link,
                        icon: i18n.$t("NavBar").AuditBorrowManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").AuditBounsManager.Label,
                        to: i18n.$t("NavBar").AuditBounsManager.Link,
                        icon: i18n.$t("NavBar").AuditBounsManager.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").SalaryManager.Label,
                        to: i18n.$t("NavBar").SalaryManager.Link,
                        icon: i18n.$t("NavBar").SalaryManager.Logo,
                      },
                    ],
            },
            {
              label: i18n.$t("NavBar").PaymentSetting.Label,
              icon: i18n.$t("NavBar").PaymentSetting.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").Payment.Label,
                  to: i18n.$t("NavBar").Payment.Link,
                  icon: i18n.$t("NavBar").Payment.Logo,
                },
                {
                  label: i18n.$t("NavBar").AuditPayment.Label,
                  to: i18n.$t("NavBar").AuditPayment.Link,
                  icon: i18n.$t("NavBar").AuditPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplicationAmount.Label,
                  to: i18n.$t("NavBar").ApplicationAmount.Link,
                  icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                },
                // {
                //   label: i18n.$t("NavBar").AuditAmount.Label,
                //   to: i18n.$t("NavBar").AuditAmount.Link,
                //   icon: i18n.$t("NavBar").AuditAmount.Logo,
                // },
                {
                  label: i18n.$t("NavBar").Sellment.Label,
                  to: i18n.$t("NavBar").Sellment.Link,
                  icon: i18n.$t("NavBar").Sellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                  to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                  icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistorySellment.Label,
                  to: i18n.$t("NavBar").SelfHistorySellment.Link,
                  icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").PaymentDetail.Label,
                  to: i18n.$t("NavBar").PaymentDetail.Link,
                  icon: i18n.$t("NavBar").PaymentDetail.Logo,
                },
                //////////////
                // {
                //   label: i18n.$t("NavBar").AuditSellment.Label,
                //   to: i18n.$t("NavBar").AuditSellment.Link,
                //   icon: i18n.$t("NavBar").AuditSellment.Logo,
                // },
              ],
            },
          ]
      : store.state.user.role == 3
      ? store.state.user.organisation_id == 1
        ? [
            {
              label: i18n.$t("NavBar").Info.Label,
              to: i18n.$t("NavBar").Info.Link,
              icon: i18n.$t("NavBar").Info.Logo,
            },
            {
              label: i18n.$t("NavBar").Salary.Label,
              icon: i18n.$t("NavBar").Salary.Logo,
              items:
                store.state.user.receipt_type == 1
                  ? [
                      {
                        label: i18n.$t("NavBar").ApplyHoliday.Label,
                        to: i18n.$t("NavBar").ApplyHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                        to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBouns.Label,
                        to: i18n.$t("NavBar").ApplyBouns.Link,
                        icon: i18n.$t("NavBar").ApplyBouns.Logo,
                      },
                    ]
                  : [
                      {
                        label: i18n.$t("NavBar").ApplyHoliday.Label,
                        to: i18n.$t("NavBar").ApplyHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                        to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBorrow.Label,
                        to: i18n.$t("NavBar").ApplyBorrow.Link,
                        icon: i18n.$t("NavBar").ApplyBorrow.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBouns.Label,
                        to: i18n.$t("NavBar").ApplyBouns.Link,
                        icon: i18n.$t("NavBar").ApplyBouns.Logo,
                      },
                    ],
            },
            {
              label: i18n.$t("NavBar").PaymentSetting.Label,
              icon: i18n.$t("NavBar").PaymentSetting.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").Payment.Label,
                  to: i18n.$t("NavBar").Payment.Link,
                  icon: i18n.$t("NavBar").Payment.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplicationAmount.Label,
                  to: i18n.$t("NavBar").ApplicationAmount.Link,
                  icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                },
                {
                  label: i18n.$t("NavBar").Sellment.Label,
                  to: i18n.$t("NavBar").Sellment.Link,
                  icon: i18n.$t("NavBar").Sellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                  to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                  icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistorySellment.Label,
                  to: i18n.$t("NavBar").SelfHistorySellment.Link,
                  icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                },
              ],
            },
          ]
        : store.state.user.salary >= 200
        ? [
            {
              label: i18n.$t("NavBar").Document.Label,
              to: i18n.$t("NavBar").Document.Link,
              icon: i18n.$t("NavBar").Document.Logo,
            },
            {
              label: i18n.$t("NavBar").Info.Label,
              to: i18n.$t("NavBar").Info.Link,
              icon: i18n.$t("NavBar").Info.Logo,
            },
            {
              label: i18n.$t("NavBar").Salary.Label,
              icon: i18n.$t("NavBar").Salary.Logo,
              items:
                store.state.user.receipt_type == 1
                  ? [
                      {
                        label: i18n.$t("NavBar").ApplyHoliday.Label,
                        to: i18n.$t("NavBar").ApplyHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                        to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBouns.Label,
                        to: i18n.$t("NavBar").ApplyBouns.Link,
                        icon: i18n.$t("NavBar").ApplyBouns.Logo,
                      },
                    ]
                  : [
                      {
                        label: i18n.$t("NavBar").ApplyHoliday.Label,
                        to: i18n.$t("NavBar").ApplyHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                        to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBorrow.Label,
                        to: i18n.$t("NavBar").ApplyBorrow.Link,
                        icon: i18n.$t("NavBar").ApplyBorrow.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBouns.Label,
                        to: i18n.$t("NavBar").ApplyBouns.Link,
                        icon: i18n.$t("NavBar").ApplyBouns.Logo,
                      },
                    ],
            },
            {
              label: i18n.$t("NavBar").PaymentSetting.Label,
              icon: i18n.$t("NavBar").PaymentSetting.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").Payment.Label,
                  to: i18n.$t("NavBar").Payment.Link,
                  icon: i18n.$t("NavBar").Payment.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplicationAmount.Label,
                  to: i18n.$t("NavBar").ApplicationAmount.Link,
                  icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                },
                {
                  label: i18n.$t("NavBar").Sellment.Label,
                  to: i18n.$t("NavBar").Sellment.Link,
                  icon: i18n.$t("NavBar").Sellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                  to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                  icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistorySellment.Label,
                  to: i18n.$t("NavBar").SelfHistorySellment.Link,
                  icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                },
              ],
            },
          ]
        : [
            {
              label: i18n.$t("NavBar").Info.Label,
              to: i18n.$t("NavBar").Info.Link,
              icon: i18n.$t("NavBar").Info.Logo,
            },
            {
              label: i18n.$t("NavBar").Salary.Label,
              icon: i18n.$t("NavBar").Salary.Logo,
              items:
                store.state.user.receipt_type == 1
                  ? [
                      {
                        label: i18n.$t("NavBar").ApplyHoliday.Label,
                        to: i18n.$t("NavBar").ApplyHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                        to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBouns.Label,
                        to: i18n.$t("NavBar").ApplyBouns.Link,
                        icon: i18n.$t("NavBar").ApplyBouns.Logo,
                      },
                    ]
                  : [
                      {
                        label: i18n.$t("NavBar").ApplyHoliday.Label,
                        to: i18n.$t("NavBar").ApplyHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
                        to: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
                        icon: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBorrow.Label,
                        to: i18n.$t("NavBar").ApplyBorrow.Link,
                        icon: i18n.$t("NavBar").ApplyBorrow.Logo,
                      },
                      {
                        label: i18n.$t("NavBar").ApplyBouns.Label,
                        to: i18n.$t("NavBar").ApplyBouns.Link,
                        icon: i18n.$t("NavBar").ApplyBouns.Logo,
                      },
                    ],
            },
            {
              label: i18n.$t("NavBar").PaymentSetting.Label,
              icon: i18n.$t("NavBar").PaymentSetting.Logo,
              items: [
                {
                  label: i18n.$t("NavBar").Payment.Label,
                  to: i18n.$t("NavBar").Payment.Link,
                  icon: i18n.$t("NavBar").Payment.Logo,
                },
                {
                  label: i18n.$t("NavBar").ApplicationAmount.Label,
                  to: i18n.$t("NavBar").ApplicationAmount.Link,
                  icon: i18n.$t("NavBar").ApplicationAmount.Logo,
                },
                {
                  label: i18n.$t("NavBar").Sellment.Label,
                  to: i18n.$t("NavBar").Sellment.Link,
                  icon: i18n.$t("NavBar").Sellment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistoryPayment.Label,
                  to: i18n.$t("NavBar").SelfHistoryPayment.Link,
                  icon: i18n.$t("NavBar").SelfHistoryPayment.Logo,
                },
                {
                  label: i18n.$t("NavBar").SelfHistorySellment.Label,
                  to: i18n.$t("NavBar").SelfHistorySellment.Link,
                  icon: i18n.$t("NavBar").SelfHistorySellment.Logo,
                },
              ],
            },
          ]
      : [],
  items:
    store.state.user == null
      ? []
      : store.state.user.role == 1
      ? [
          {
            label: i18n.$t("NavBar").Info.Label,
            link: i18n.$t("NavBar").Info.Link,
            logo: i18n.$t("NavBar").Info.Logo,
          },
          // {
          //   label: i18n.$t("NavBar").ApplyHoliday.Label,
          //   link: i18n.$t("NavBar").ApplyHoliday.Link,
          //   logo: i18n.$t("NavBar").ApplyHoliday.Logo,
          // },
          // {
          //   label: i18n.$t("NavBar").ApplyBorrow.Label,
          //   link: i18n.$t("NavBar").ApplyBorrow.Link,
          //   logo: i18n.$t("NavBar").ApplyBorrow.Logo,
          // },
          {
            label: i18n.$t("NavBar").EmployerAdmin.Label,
            link: i18n.$t("NavBar").EmployerAdmin.Link,
            logo: i18n.$t("NavBar").EmployerAdmin.Logo,
          },
          {
            label: i18n.$t("NavBar").ApplySalaryAdmin.Label,
            link: i18n.$t("NavBar").ApplySalaryAdmin.Link,
            logo: i18n.$t("NavBar").ApplySalaryAdmin.Logo,
          },
          {
            label: i18n.$t("NavBar").AuditSalaryAdmin.Label,
            link: i18n.$t("NavBar").AuditSalaryAdmin.Link,
            logo: i18n.$t("NavBar").AuditSalaryAdmin.Logo,
          },
          {
            label: i18n.$t("NavBar").AuditHolidayAdmin.Label,
            link: i18n.$t("NavBar").AuditHolidayAdmin.Link,
            logo: i18n.$t("NavBar").AuditHolidayAdmin.Logo,
          },
          {
            label: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Label,
            link: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Link,
            logo: i18n.$t("NavBar").AuditGiveUpHolidayAdmin.Logo,
          },
          {
            label: i18n.$t("NavBar").AuditBorrowAdmin.Label,
            link: i18n.$t("NavBar").AuditBorrowAdmin.Link,
            logo: i18n.$t("NavBar").AuditBorrowAdmin.Logo,
          },
          {
            label: i18n.$t("NavBar").SalaryAdmin.Label,
            link: i18n.$t("NavBar").SalaryAdmin.Link,
            logo: i18n.$t("NavBar").SalaryAdmin.Logo,
          },
          //////////////
          {
            label: i18n.$t("NavBar").Organisations.Label,
            link: i18n.$t("NavBar").Organisations.Link,
            logo: i18n.$t("NavBar").Organisations.Logo,
          },
          {
            label: i18n.$t("NavBar").Department.Label,
            link: i18n.$t("NavBar").Department.Link,
            logo: i18n.$t("NavBar").Department.Logo,
          },
          {
            label: i18n.$t("NavBar").Nationality.Label,
            link: i18n.$t("NavBar").Nationality.Link,
            logo: i18n.$t("NavBar").Nationality.Logo,
          },
          {
            label: i18n.$t("NavBar").Position.Label,
            link: i18n.$t("NavBar").Position.Link,
            logo: i18n.$t("NavBar").Position.Logo,
          },
          {
            label: i18n.$t("NavBar").WorkPermit.Label,
            link: i18n.$t("NavBar").WorkPermit.Link,
            logo: i18n.$t("NavBar").WorkPermit.Logo,
          },
          {
            label: i18n.$t("NavBar").ExchangeRate.Label,
            link: i18n.$t("NavBar").ExchangeRate.Link,
            logo: i18n.$t("NavBar").ExchangeRate.Logo,
          },
          {
            label: i18n.$t("NavBar").Currency.Label,
            link: i18n.$t("NavBar").Currency.Link,
            logo: i18n.$t("NavBar").Currency.Logo,
          },
          {
            label: i18n.$t("NavBar").SalaryItem.Label,
            link: i18n.$t("NavBar").SalaryItem.Link,
            logo: i18n.$t("NavBar").SalaryItem.Logo,
          },
          {
            label: i18n.$t("NavBar").HolidayItem.Label,
            link: i18n.$t("NavBar").HolidayItem.Link,
            logo: i18n.$t("NavBar").HolidayItem.Logo,
          },
          {
            label: i18n.$t("NavBar").Factory.Label,
            link: i18n.$t("NavBar").Factory.Link,
            logo: i18n.$t("NavBar").Factory.Logo,
          },
          {
            label: i18n.$t("NavBar").Company.Label,
            link: i18n.$t("NavBar").Company.Link,
            logo: i18n.$t("NavBar").Company.Logo,
          },
          {
            label: i18n.$t("NavBar").CompanyAccount.Label,
            link: i18n.$t("NavBar").CompanyAccount.Link,
            logo: i18n.$t("NavBar").CompanyAccount.Logo,
          },
          {
            label: i18n.$t("NavBar").AccountType.Label,
            link: i18n.$t("NavBar").AccountType.Link,
            logo: i18n.$t("NavBar").AccountType.Logo,
          },
          {
            label: i18n.$t("NavBar").AccountItem.Label,
            link: i18n.$t("NavBar").AccountItem.Link,
            logo: i18n.$t("NavBar").AccountItem.Logo,
          },

          {
            label: i18n.$t("NavBar").PaymentItem.Label,
            link: i18n.$t("NavBar").PaymentItem.Link,
            logo: i18n.$t("NavBar").PaymentItem.Logo,
          },
          {
            label: i18n.$t("NavBar").Payment.Label,
            link: i18n.$t("NavBar").Payment.Link,
            logo: i18n.$t("NavBar").Payment.Logo,
          },
          //////////////
          {
            label: i18n.$t("NavBar").AuditPayment.Label,
            link: i18n.$t("NavBar").AuditPayment.Link,
            logo: i18n.$t("NavBar").AuditPayment.Logo,
          },
          {
            label: i18n.$t("NavBar").ApplicationAmount.Label,
            link: i18n.$t("NavBar").ApplicationAmount.Link,
            logo: i18n.$t("NavBar").ApplicationAmount.Logo,
          },
          {
            label: i18n.$t("NavBar").AuditAmount.Label,
            link: i18n.$t("NavBar").AuditAmount.Link,
            logo: i18n.$t("NavBar").AuditAmount.Logo,
          },
          {
            label: i18n.$t("NavBar").Sellment.Label,
            link: i18n.$t("NavBar").Sellment.Link,
            logo: i18n.$t("NavBar").Sellment.Logo,
          },
          //////////////
          {
            label: i18n.$t("NavBar").AuditSellment.Label,
            link: i18n.$t("NavBar").AuditSellment.Link,
            logo: i18n.$t("NavBar").AuditSellment.Logo,
          },
          {
            label: i18n.$t("NavBar").HistoryPayment.Label,
            link: i18n.$t("NavBar").HistoryPayment.Link,
            logo: i18n.$t("NavBar").HistoryPayment.Logo,
          },
          {
            label: i18n.$t("NavBar").HistorySellment.Label,
            link: i18n.$t("NavBar").HistorySellment.Link,
            logo: i18n.$t("NavBar").HistorySellment.Logo,
          },
          {
            label: i18n.$t("NavBar").History.Label,
            link: i18n.$t("NavBar").History.Link,
            logo: i18n.$t("NavBar").History.Logo,
          },
        ]
      : store.state.user.role == 2
      ? [
          {
            label: i18n.$t("NavBar").Info.Label,
            link: i18n.$t("NavBar").Info.Link,
            logo: i18n.$t("NavBar").Info.Logo,
          },
          {
            label: i18n.$t("NavBar").ApplyHoliday.Label,
            link: i18n.$t("NavBar").ApplyHoliday.Link,
            logo: i18n.$t("NavBar").ApplyHoliday.Logo,
          },
          {
            label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
            link: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
            logo: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
          },
          {
            label: i18n.$t("NavBar").ApplyBorrow.Label,
            link: i18n.$t("NavBar").ApplyBorrow.Link,
            logo: i18n.$t("NavBar").ApplyBorrow.Logo,
          },
          {
            label: i18n.$t("NavBar").EmployerManager.Label,
            link: i18n.$t("NavBar").EmployerManager.Link,
            logo: i18n.$t("NavBar").EmployerManager.Logo,
          },
          {
            label: i18n.$t("NavBar").ApplySalaryManager.Label,
            link: i18n.$t("NavBar").ApplySalaryManager.Link,
            logo: i18n.$t("NavBar").ApplySalaryManager.Logo,
          },
          {
            label: i18n.$t("NavBar").AuditHolidayManager.Label,
            link: i18n.$t("NavBar").AuditHolidayManager.Link,
            logo: i18n.$t("NavBar").AuditHolidayManager.Logo,
          },
          {
            label: i18n.$t("NavBar").AuditGiveUpHolidayManager.Label,
            link: i18n.$t("NavBar").AuditGiveUpHolidayManager.Link,
            logo: i18n.$t("NavBar").AuditGiveUpHolidayManager.Logo,
          },
          {
            label: i18n.$t("NavBar").AuditBorrowManager.Label,
            link: i18n.$t("NavBar").AuditBorrowManager.Link,
            logo: i18n.$t("NavBar").AuditBorrowManager.Logo,
          },
          {
            label: i18n.$t("NavBar").SalaryManager.Label,
            link: i18n.$t("NavBar").SalaryManager.Link,
            logo: i18n.$t("NavBar").SalaryManager.Logo,
          },
          {
            label: i18n.$t("NavBar").Payment.Label,
            link: i18n.$t("NavBar").Payment.Link,
            logo: i18n.$t("NavBar").Payment.Logo,
          },
          {
            label: i18n.$t("NavBar").AuditPayment.Label,
            link: i18n.$t("NavBar").AuditPayment.Link,
            logo: i18n.$t("NavBar").AuditPayment.Logo,
          },
          {
            label: i18n.$t("NavBar").ApplicationAmount.Label,
            link: i18n.$t("NavBar").ApplicationAmount.Link,
            logo: i18n.$t("NavBar").ApplicationAmount.Logo,
          },
          // {
          //   label: i18n.$t("NavBar").AuditAmount.Label,
          //   link: i18n.$t("NavBar").AuditAmount.Link,
          //   logo: i18n.$t("NavBar").AuditAmount.Logo,
          // },
          {
            label: i18n.$t("NavBar").Sellment.Label,
            link: i18n.$t("NavBar").Sellment.Link,
            logo: i18n.$t("NavBar").Sellment.Logo,
          },
          //////////////
          // {
          //   label: i18n.$t("NavBar").AuditSellment.Label,
          //   link: i18n.$t("NavBar").AuditSellment.Link,
          //   logo: i18n.$t("NavBar").AuditSellment.Logo,
          // },
        ]
      : store.state.user.role == 3
      ? [
          {
            label: i18n.$t("NavBar").Info.Label,
            link: i18n.$t("NavBar").Info.Link,
            logo: i18n.$t("NavBar").Info.Logo,
          },
          {
            label: i18n.$t("NavBar").ApplyHoliday.Label,
            link: i18n.$t("NavBar").ApplyHoliday.Link,
            logo: i18n.$t("NavBar").ApplyHoliday.Logo,
          },
          {
            label: i18n.$t("NavBar").ApplyGiveUpHoliday.Label,
            link: i18n.$t("NavBar").ApplyGiveUpHoliday.Link,
            logo: i18n.$t("NavBar").ApplyGiveUpHoliday.Logo,
          },
          {
            label: i18n.$t("NavBar").ApplyBorrow.Label,
            link: i18n.$t("NavBar").ApplyBorrow.Link,
            logo: i18n.$t("NavBar").ApplyBorrow.Logo,
          },
          {
            label: i18n.$t("NavBar").Payment.Label,
            link: i18n.$t("NavBar").Payment.Link,
            logo: i18n.$t("NavBar").Payment.Logo,
          },
          {
            label: i18n.$t("NavBar").ApplicationAmount.Label,
            link: i18n.$t("NavBar").ApplicationAmount.Link,
            logo: i18n.$t("NavBar").ApplicationAmount.Logo,
          },
          {
            label: i18n.$t("NavBar").Sellment.Label,
            link: i18n.$t("NavBar").Sellment.Link,
            logo: i18n.$t("NavBar").Sellment.Logo,
          },
        ]
      : [],
});
</script>

<template>
  <Toolbar key="1" class="toolbar">
    <template #start>
      <Button
        class="p-button-info"
        icon="pi pi-align-justify"
        style="background: #1c0166 !important"
        @click="data.visibleLeft = true"
      />
      <!-- <img alt="logo" :src="logo" class="p-mr-2 logo p-ml-6" /> -->
    </template>

    <template #end>
      <span class="p-mr-2" style="color: white"> TEXRAY集團薪資系統 </span>
      <Dropdown
        v-model="store.state.lang"
        :options="data.lang"
        @change="langSwitch"
        optionLabel="name"
        optionValue="code"
      />
      <Button
        class="p-button-danger p-ml-1"
        icon="pi pi-sign-out"
        iconPos="left"
        @click="Logout"
      ></Button>
    </template>
  </Toolbar>
  <Toolbar
    key="2"
    class="toolbar_detail p-d-none"
    style="
      padding-top: 8px !important;
      white-space: nowrap;
      overflow: scroll;
      z-index: 100;
    "
  >
    <template #start>
      <Button
        class="p-button-info"
        icon="pi pi-align-justify"
        @click="data.visibleLeft = true"
      />
      <!-- <div
        class="button p-mr-2 p-pr-1 p-pt-1 p-pb-1 p-pl-1"
        @click="routerSwitch('/')"
      >
        {{ i18n.$t("Material").Title }}
      </div> -->
    </template>
  </Toolbar>
  <Sidebar v-model:visible="data.visibleLeft" position="left">
    <!-- <div class="p-grid">
      <Button
        :key="index"
        v-for="(item, index) in data.items"
        class="p-button-lg p-button-info p-col-12 p-button-text p-pt-2 p-pb-3"
        :icon="item.logo"
        :label="item.label"
        @click="routerSwitch(item.link)"
      />
    </div> -->
    <PanelMenu :model="data.settings" v-model:expandedKeys="expandedKeys" />
    <!-- <MegaMenu :model="data.settings" orientation="vertical">
     <template #item="{ item }">
        <router-link
          :to="item.to"
          custom
          v-slot="{ href, route, navigate, isActive, isExactActive }"
        >
          <a
            :href="href"
            @click="navigate"
            :class="{
              'active-link': isActive,
              'active-link-exact': isExactActive,
            }"
            >{{ route.fullPath }}</a
          >
        </router-link>
      </template> 
    </MegaMenu>-->
  </Sidebar>
</template>

<style lang="scss">
.button {
  width: auto;
  cursor: pointer;
  border: 2px dotted;
}
.button:hover {
  color: rgb(199, 6, 6);
  border-bottom: 0.5rem solid;
}
.toolbar_detail {
  .pointer {
    cursor: pointer;
  }
  min-width: 375px;
  height: 50px;
  left: 0px;
  /* overflow: hidden; */
  border-top: none !important;
  background: rgb(252, 252, 252) !important;
  border-bottom: 0.1rem solid !important;
  position: fixed; /* Set the navbar to fixed position */
  width: 100%;
  top: 85px;
  z-index: 0;
}
.toolbar {
  .pointer {
    cursor: pointer;
  }
  min-width: 375px;
  height: 85px;
  left: 0px;
  /* overflow: hidden; */
  background: rgb(28, 1, 102) !important;
  position: fixed; /* Set the navbar to fixed position */
  width: 100%;
  top: 0;
  z-index: 99;
  .logo {
    position: fixed; /* Set the navbar to fixed position */
    top: 5;
    height: 65px;
  }
}
</style>
