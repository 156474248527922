export function printHTML(dom) {
  // setTimeout(() => {
  var newWindow = window.open("Print", "_blank");
  // 抓取所有 canvas 元素並將它們轉換為圖片
  const canvasElements = dom.querySelectorAll("canvas");
  const canvasPromises = Array.from(canvasElements).map((canvas) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = canvas.toDataURL("image/png"); // 将 canvas 转换为 PNG 图像
      // img.style.width = canvas.style.width;
      // img.style.height = canvas.style.height;
      img.style.width = "100%"; // 设置宽度为100%以自适应容器
      img.style.height = "auto"; // 通过auto来保持比例
      img.style.objectFit = "contain"; // 确保图像不会被切割
      img.style.display = "block"; // 去除默认的行内间距
      // 当图片加载完成后，用图像替换 canvas
      img.onload = () => {
        canvas.replaceWith(img);
        resolve(); // 处理完毕，返回结果
      };
    });
  });
  Promise.all(canvasPromises).then(() => {
    console.log(canvasElements);
    // 抓取當前頁面的所有樣式表連結
    const resources = `
  <style>
    .p-col-12 { width: 100%; }
.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
    display:none;
}
    .p-col-6 { width: 50%; }
    .p-grid { display: flex; flex-wrap: wrap; margin: -0.5rem; }
    .p-component { font-family: 'Segoe UI', sans-serif; font-size: 1rem; }
    .p-datatable table { border-collapse: collapse; width: 100%; }

    .p-datatable-resizable .p-resizable-column:not(.p-frozen-column) {
    background-clip: padding-box;
    position: relative;
}
    .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: black;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}
    .p-column-title{
    color:black;
    font-size:14px;
     font-weight: 600;
    }
    .p-datatable .p-datatable-tbody tr td { padding: 1rem; border-bottom: 1px solid #e9ecef; }
  </style>
`;
    var docStr = dom.innerHTML;
    // newWindow.document.write(docStr);
    newWindow.document.open();
    newWindow.document.write(`
    <html>
      <head>
        <title>Print</title>
        ${resources}  <!-- 引入樣式表 -->
      </head>
      <body>
        ${docStr}  <!-- DOM 內容 -->
      </body>
    </html>
  `);

    newWindow.document.close(); // 确保文档流关闭
    setTimeout(() => {
      newWindow.focus(); // 让新窗口获得焦点
      newWindow.print(); // 打印页面
      newWindow.close(); // 打印完成后关闭窗口
    }, 1000);
    // newWindow.onload = () => {
    //   newWindow.focus();
    //   newWindow.print();
    //   newWindow.close();
    // };
  });
  // }, 1000);
}
